import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useFetchOperation } from '../../service/Operation';
import { useAppDispatch, useAppSelector } from '../../store';
import { trainingActionCreators } from '../../store/Training';
import { TrainingCard } from '../../types/TrainingTypes';
import ExternalLinks from '../card/edit/ExternalLinks';
import ProgressButton from './../general/ProgressButton';

export default function ServicePanel(props: { card: TrainingCard }) {
    const auth = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
    const [marking, startMarking, resetMarking] = useFetchOperation(onMarkSuccess);

    function skipCard() {
        dispatch(trainingActionCreators.skip());
    }

    function markCard() {
        startMarking('post', `api/marks/${props.card.id}`);
    }

    function onMarkSuccess() {
        setTimeout(resetMarking, 1000);
    }

    return (
        <>
            {props.card &&
                < div className="btn-panel my-2">
                    <ExternalLinks card={props.card}
                        modeInfo={props.card.modeInfo} />

                    {auth.user &&
                        <>
                            <Button variant="outline-primary"
                                size="sm"
                                className="btn-wide"
                                onClick={skipCard}>
                                Don't study
                            </Button>

                            <ProgressButton variant="outline-primary"
                                size="sm"
                                className="btn-wide"
                                onClick={markCard}
                                active={marking.active}
                                success={marking.success}
                                text="Send to check"
                                successText="Sent to check" />

                            {props.card.modeInfo.isEditable &&
                                <Link to={`/cards/${props.card.id}`}
                                    className="btn btn-sm btn-outline-primary btn-wide">
                                    Edit
                                </Link>
                            }

                            {auth.isModerator &&
                                <Link to={`/cards/${props.card.id}/similar`}
                                    className="btn btn-sm btn-outline-primary btn-wide">
                                    Similar
                                </Link>
                            }
                        </>
                    }
                </div>
            }
        </>
    );
}
