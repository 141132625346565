import * as React from 'react';
import { useDispatch } from 'react-redux';
import apiClient from '../../service/ApiClient';
import { useFetchOperation } from '../../service/Operation';
import { modeActionCreators } from '../../store/Mode';
import { ModeSetting, ModeSettingSlim, UserModesSettings } from '../../types/TrainingTypes';
import Alerts from '../general/Alerts';
import ProgressButton from '../general/ProgressButton';
import ModeSelector from './ModeSelector';
import ModeSettingEditor from './ModeSettingEditor';

export default function Single(props: {
    settings: UserModesSettings,
    setSettings: (x: UserModesSettings) => void
}) {
    const dispatch = useDispatch();
    const [setting, setSetting] = React.useState(props.settings.single);
    const [isGetting, setIsGetting] = React.useState(false);
    const [editing, startEditing, resetEditing] = useFetchOperation(onEditingSuccess);

    function onDictionaryChange(dictionaryId: number, dictionaryName: string) {
        resetEditing()
        const newSettings = { ...setting, dictionaryId, dictionaryName };
        setSetting(newSettings);
        getModeSetting(newSettings)
    }

    function onIsDirectChange(isDirect: boolean) {
        resetEditing()
        const newSettings = { ...setting, isDirect };
        setSetting(newSettings);
        getModeSetting(newSettings)
    }

    function getModeSetting(newSettings: ModeSetting) {
        setIsGetting(true)
        apiClient.get(`api/study/settings/mode-setting?dictionaryId=${newSettings.dictionaryId}&isDirect=${newSettings.isDirect}`)
            .then(data => {
                setIsGetting(false);
                setSetting({ ...newSettings, ...data });
            });
    }

    function saveSingle() {
        startEditing('post', `api/study/settings/single`, setting);
    }

    function onEditingSuccess() {
        dispatch(modeActionCreators.changeSuccess(setting));
        setTimeout(resetEditing, 2000);
    }

    function setSettingSlim(settingSlim: ModeSettingSlim) {
        setSetting({ ...setting, ...settingSlim });
    }

    return (
        <>
            <div className="mt-1">
                <div className="border border-primary rounded-3 bg-light w-auto mx-auto pt-1 pb-2 px-3 d-inline-block">
                    <ModeSelector dictionaries={props.settings.dictionaries}
                        dictionaryId={setting.dictionaryId}
                        isDirect={setting.isDirect}
                        onDictionaryChange={onDictionaryChange}
                        onIsDirectChange={onIsDirectChange} />

                    <ModeSettingEditor setting={setting}
                        setSetting={setSettingSlim}
                        isGetting={isGetting} />
                </div>
            </div>

            <ProgressButton className="my-3 btn-wide"
                onClick={saveSingle}
                disabled={isGetting}
                active={editing.active}
                success={editing.success} />

            <Alerts error={editing.error}
                onClose={resetEditing} />
        </>
    )
}

