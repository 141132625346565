import React, { ChangeEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFetchOperation } from '../../service/Operation';
import { UserModesSettings } from '../../types/TrainingTypes';
import Error from '../general/Error';
import Loader from '../general/Loader';
import Mixed from './Mixed';
import Single from './Single';

export default function TrainingSettings() {
    const [settings, setSettings] = useState<UserModesSettings>();

    const [getting, startGetting] = useFetchOperation(setSettings, undefined, true);

    React.useEffect(() => {
        startGetting('get', 'api/study/settings');
        document.title = `Studying settings - ${(document as any).rootTitle}`;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function oneManyChanged(e: ChangeEvent<HTMLInputElement>) {

        setSettings({ ...settings!, mixedActive: e.target.value === 'true' })
    }

    return (
        <>
            {getting.active ?
                <Loader />
                :
                !settings ?
                    <Error text={getting.error} />
                    :
                    <div>
                        <h1>Studying settings</h1>

                        <div className="my-3">
                            <div className="form-check form-check-inline">
                                <Form.Check
                                    type="radio"
                                    name="oneMany"
                                    id="radio1"
                                    value="false"
                                    checked={!settings.mixedActive}
                                    onChange={oneManyChanged} />
                                <label className="form-check-label" htmlFor="radio1">
                                    One dictionary
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <Form.Check
                                    type="radio"
                                    name="oneMany"
                                    id="radio2"
                                    value="true"
                                    checked={settings.mixedActive}
                                    onChange={oneManyChanged} />
                                <label className="form-check-label" htmlFor="radio2">
                                    Several dictionaries
                                </label>
                            </div>
                        </div>

                        {!settings.mixedActive
                            ?
                            <Single settings={settings}
                                setSettings={setSettings} />
                            :
                            <Mixed settings={settings}
                                setSettings={setSettings} />}
                    </div>
            }
        </>
    )
}
