import * as React from 'react';
import { Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../store';
import { combinedActionCreators } from '../../store/Combined';
import { trainingActionCreators } from '../../store/Training';
import { ChoiceValue, InterpretValue, Variant } from '../../types/TrainingTypes';
import ChoiceVariants from './ChoiceVariants';

export default function Combined() {
    const trainingState = useAppSelector(state => state.training);
    const state = useAppSelector(state => state.combined);
    const dispatch = useAppDispatch();
    const [isTimerActive, setIsTimerActive] = React.useState(false);

    function showVariants(isTimerElapsed: boolean) {
        dispatch(combinedActionCreators.showVariants(isTimerElapsed));
    }

    function nextCard() {
        dispatch(trainingActionCreators.nextCard());
    }

    function chooseVariant(variant: Variant | undefined, isLong: boolean) {
        if (!isTimerActive) {
            const isCorrect = variant?.content === state.solution;
            if (trainingState.isSolutionShown) {
                if (isCorrect || !trainingState.isServicePanelEnabled)
                    nextCard();
            }
            else {
                if (isCorrect && !trainingState.isServicePanelEnabled) {
                    setIsTimerActive(true);
                    setTimeout(() => {
                        setIsTimerActive(false);
                        nextCard();
                    }, 500);
                }
                dispatch(combinedActionCreators.chooseVariant(variant));

                if (!state.isTimerElapsed || !variant) {
                    const assessmentValue = !variant
                        ? isLong
                            ? InterpretValue.Reset
                            : InterpretValue.DoNotKnow
                        : isCorrect
                            ? isLong
                                ? InterpretValue.KnowWell
                                : InterpretValue.Know
                            : isLong
                                ? InterpretValue.MinimalDecrease
                                : InterpretValue.DoNotKnow;
                    dispatch(trainingActionCreators.interpretAssess(assessmentValue));
                }
                else {
                    const assessmentValue = isCorrect
                        ? isLong
                            ? ChoiceValue.Know
                            : ChoiceValue.Correct
                        : isLong
                            ? ChoiceValue.Decrease
                            : ChoiceValue.Incorrect;
                    const time = new Date().getTime() - new Date(state.shownTime!).getTime();

                    let difficulty = 0;
                    let passedCorrect = false;
                    for (let i = 0; i < state.variants.length; i++) {
                        difficulty += Math.min(6 + state.variants[i].content.length, passedCorrect ? 9 : 15);
                        passedCorrect ||= (state.variants[i].content === variant?.content);
                    }
                    dispatch(trainingActionCreators.choiceAssess(assessmentValue, time, difficulty));
                }
            }
        }
    }

    function resetTimer() {
        dispatch(combinedActionCreators.resetTimer());
    }

    return (
        <>
            <div className="flex-grow-1 w-100 width-limit text-break">
                <div style={{ minHeight: '5.5rem' }}>
                    <div className="task-box mb-3"
                        onDoubleClick={resetTimer}>
                        <span>{state.task}</span>
                        {state.taskAux
                            && <span className="fs-2 text-secondary"> {state.taskAux}</span>
                        }
                    </div>
                </div>

                {!state.isVariantsShown ?
                    <div className="row g-2 g-sm-3 my-3">
                        <div className="col-12 col-sm-6">
                            <Button variant="light"
                                size="lg"
                                className="col col-sm-6"
                                onClick={() => showVariants(true)}>
                                {!state.isTimerElapsed ? "Can't remember" : 'Show variants'}
                            </Button>
                        </div>
                        <div className="col-12 col-sm-6">
                            <Button variant="light"
                                size="lg"
                                className="col col-sm-6"
                                disabled={state.isTimerElapsed}
                                onClick={() => showVariants(false)}>
                                {!state.isTimerElapsed ? `I have a guess (${state.interval})` : 'Time to make guess elapsed'}
                            </Button>
                        </div>
                    </div>
                    :
                    <ChoiceVariants variants={state.variants}
                        backgrounds={state.backgrounds}
                        chooseVariant={chooseVariant}
                        showUnknown={!state.isTimerElapsed} />
                }
                {state.wrongVariant &&
                    <div className="fs-5"><span className="red">{state.wrongVariant.content}</span> - <span className="fw-bold">{state.wrongVariant.wrongVariant}</span></div>
                }
                {trainingState.isSolutionShown && trainingState.isServicePanelEnabled &&
                    <>
                        {trainingState.card?.example &&
                            <div className="task-box mt-3">
                                <div className="text-secondary">{trainingState.card.example}</div>
                            </div>
                        }
                        {trainingState.card?.similar &&
                            <ul className="list-unstyled mt-2 mb-0 fs-5">
                                {trainingState.card.similar.map(x =>
                                    <li key={x.id}><span className="fw-bold">{x.challenge}</span> - {x.answer}</li>
                                )}
                            </ul>
                        }
                    </>
                }
            </div>
        </>
    );
}
