import { Route, Routes } from 'react-router';
import { Outlet } from 'react-router-dom';
import SignIn from '../auth/SignIn';
import SignInGoogle from '../auth/SignInGoogle';
import './App.css';
import Layout from './Layout';
import BulkEdit from '../dictionary/BulkEdit';
import DicAdd from '../dictionary/DicAdd';
import DicEdit from '../dictionary/DicEdit';
import DicList from '../dictionary/DicList';
import DicView from '../dictionary/DicView';
import HandleCards from '../dictionary/HandleCards';
import Reorder from '../dictionary/Reorder';
import Preview from '../training/Preview';
import Select from '../training/Select';
import Study from '../training/Study';
import Training from '../training/Training';
import UserCardResults from '../user/cardResults/UserCardResults';
import UserHistory from '../user/history/UserHistory';
import DayStatEdit from '../user/history/DayStatEdit';
import UserSimilarCards from '../user/UserSimilarCards';
import UserStat from '../user/stat/UserStat';
import UserView from '../user/UserView';
import { TrainingType } from '../../types/TrainingTypes';
import CardAdd from '../card/CardAdd';
import CardSimilar from '../card/CardSimilar';
import Marks from '../card/Marks';
import SearchCards from '../card/SearchCards';
import CardEdit from '../card/edit/CardEdit';
import CardResults from '../card/CardResults';
import LangAdd from '../language/LangAdd';
import LangEdit from '../language/LangEdit';
import LangList from '../language/LangList';
import ResultEdit from '../result/ResultEdit';
import TrainingSettings from '../trainingSettings/TrainingSettings';
import RoleEdit from '../user/RoleEdit';
import UserList from '../user/UserList';

export default function App() {
    return (
        <Layout>
            <Routes>
                <Route path="signin-google" element={<SignInGoogle />} />
                <Route path="signin" element={<SignIn />} />

                <Route path='/' element={<Study />}>
                    <Route path='/' element={<Select />} />
                    <Route path='/preview' element={<Preview />} />
                    <Route path='/auto' element={<Training trainingType={TrainingType.Auto} />} />
                    <Route path='/interpret' element={<Training trainingType={TrainingType.Interpret} />} />
                    <Route path='/choice' element={<Training trainingType={TrainingType.Choice} />} />
                    <Route path='/combined' element={<Training trainingType={TrainingType.Combined} />} />
                    <Route path='/preview/interpret' element={<Training trainingType={TrainingType.Interpret} isPreview={true} />} />
                    <Route path='/preview/choice' element={<Training trainingType={TrainingType.Choice} isPreview={true} />} />
                    <Route path='/preview/combined' element={<Training trainingType={TrainingType.Combined} isPreview={true} />} />
                    <Route path='/preview/auto' element={<Training trainingType={TrainingType.Auto} isPreview={true} />} />
                    <Route path='/hard' element={<Training trainingType={TrainingType.Choice} isHard={true} />} />
                </Route>
                <Route path='user' element={<Outlet />}>
                    <Route path='list' element={<UserList />} />
                    <Route path=':id/role' element={<RoleEdit />} />
                    <Route path=':id' element={<UserView />} />
                    <Route path=':id/dictionary/:dictionaryId/stat' element={<UserStat />} />
                    <Route path=':id/dictionary/:dictionaryId/results' element={<UserCardResults />} />
                    <Route path=':id/dictionary/:dictionaryId/similar-cards' element={<UserSimilarCards />} />
                    <Route path=':id/dictionary/:dictionaryId/history' element={<UserHistory />} />
                    <Route path=':id/dictionary/:dictionaryId/history/:date' element={<DayStatEdit />} />
                </Route>
                <Route path='studying-settings' element={<TrainingSettings />} />
                <Route path='dictionaries' element={<DicList />} />
                <Route path='dictionaries/:id' element={<DicView />} />
                <Route path='dictionaries/:id/edit' element={<DicEdit />} />
                <Route path='dictionaries/:id/beautify-cards' element={<HandleCards />} />
                <Route path='dictionaries/:id/bulk-edit' element={<BulkEdit />} />
                <Route path='dictionaries/:id/reorder' element={<Reorder />} />
                <Route path='dictionaries/add' element={<DicAdd />} />
                <Route path='cards/add/:id' element={<CardAdd />} />
                <Route path='cards/add' element={<CardAdd />} />
                <Route path='cards/:id' element={<CardEdit />} />
                <Route path='cards/:id/similar' element={<CardSimilar />} />
                <Route path='cards/:id/results' element={<CardResults />} />
                <Route path='cards/search/:dictionaryId' element={<SearchCards />} />
                <Route path='cards/search' element={<SearchCards />} />
                <Route path='cards/marked' element={<Marks />} />
                <Route path='cards/marked/:id' element={<Marks />} />
                <Route path='results/:id' element={<ResultEdit />} />
                <Route path='languages' element={<LangList />} />
                <Route path='languages/add' element={<LangAdd />} />
                <Route path='languages/:id' element={<LangEdit />} />
            </Routes>
        </Layout>
    );
}