export default function getRate(value: number | undefined) {
    if (value) {
        let decimals;
        if (value > 1000)
            decimals = 0;
        else if (value > 10)
            decimals = 1;
        else if (value > 1.1)
            decimals = 2;
        else
            decimals = 3;
        return value.toFixed(decimals);
    }
}