import * as React from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useFetchOperation } from '../../../service/Operation';
import { useAppDispatch } from '../../../store';
import { trainingActionCreators } from '../../../store/Training';
import { Card, EditCard, CardHandler, HandledCard } from '../../../types/Common';
import { TrainingCard } from '../../../types/TrainingTypes';
import Alerts from '../../general/Alerts';
import ProgressButton from '../../general/ProgressButton';

export default function HandlingPanel(props: {
    card: EditCard,
    cardHandlerId: number | undefined,
    cardHandlers: CardHandler[],
    updateCard: (c: Card) => void,
    onCardHandlerIdChange: (c: number | undefined) => void,
    toggleHandlingActive: () => void,

}) {
    const dispatch = useAppDispatch();

    const { card, cardHandlers } = props;
    const cardHandlerId = props.cardHandlerId ?? (cardHandlers.length ? cardHandlers[0].id : undefined);
    const [handling, startHandling, resetHandling] = useFetchOperation(onHandlingSuccess);
    const [handledState, setHandledState] = React.useState<HandledCard>();

    function onHandlerChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const v = event.target.value;
        const value = isNaN(parseInt(v)) ? undefined : parseInt(v);
        props.onCardHandlerIdChange(value);
    }

    function onHandleClick() {
        const body = {
            cardId: card.id,
            cardHandlerId
        };
        startHandling('post', `api/cards/handle`, body)
    }

    function onHandlingSuccess(card: HandledCard) {
        if (card) {
            dispatch(trainingActionCreators.refreshCard(card as unknown as TrainingCard));
            props.updateCard(card);
            setHandledState(card);
        }
    }

    function onToggleHandlingClick() {
        props.toggleHandlingActive();
    }

    return (
        <>
            <InputGroup className="my-3">
                <InputGroup.Text className="px-1 px-sm-2 d-none d-sm-flex" >
                    <label htmlFor="handler">Beautifier</label>
                </InputGroup.Text>

                <Form.Select
                    id="handler"
                    value={cardHandlerId ?? ''}
                    onChange={onHandlerChange}>
                    {cardHandlers?.map(d =>
                        <option value={d.id} key={d.id}>{d.name}</option>)}
                </Form.Select>

                <ProgressButton variant="primary"
                    className="mx-0 px-1 px-sm-2"
                    onClick={onHandleClick}
                    active={handling.active}
                    text="Beautify"
                    success={handling.success} />

                <Button variant="secondary"
                    className="px-1 px-sm-2"
                    onClick={onToggleHandlingClick}
                    disabled={handling.active}>
                    Cancel
                </Button>
            </InputGroup>

            <Alerts success={handling.success}
                error={handling.error}
                successContent={handledState?.isChanged ? 'Card was successfully beautified' : `${handledState?.isRequested ? 'Requested' : 'Not requested'}, not changed${handledState?.isDuplicate ? ', duplicate' : ''}${handledState?.isInvalid ? ', invalid' : ''}`}
                onClose={() => resetHandling()} />
        </>
    );
}
