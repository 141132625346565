import './index.scss';
//import 'bootstrap/dist/css/bootstrap.css';
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import store from './store/index'
import { authActionCreators } from './store/Auth'
import App from './components/layout/App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')!;
const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);
(window as any).store = store;
store.dispatch(authActionCreators.initialize());
(document as any).rootTitle = "Improve Your Vocabulary";

root.render(
    <BrowserRouter basename={baseUrl}>
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>);
