import { Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../store';
import { trainingActionCreators } from '../../store/Training';
import { InterpretValue } from '../../types/TrainingTypes';
import { interpretActionCreators } from './../../store/Interpret';
import InterpretAssess from './InterpretAssess';

export default function Interpret() {
    const trainingState = useAppSelector(state => state.training);
    const state = useAppSelector(state => state.interpret);
    const dispatch = useAppDispatch();

    function getHint() {
        dispatch(interpretActionCreators.showHint());
    }

    function showExample() {
        dispatch(interpretActionCreators.showExample());
    }

    function showSolution() {
        dispatch(interpretActionCreators.showSolution());
    }

    function assess(assessmentValue: InterpretValue) {
        dispatch(trainingActionCreators.interpretAssess(assessmentValue));
        dispatch(trainingActionCreators.nextCard());
    };

    return (
        <>
            <div className="flex-grow-1 w-100 width-limit d-flex flex-column text-break">
                <div style={{ minHeight: '10.7rem' }}>
                    <div className="task-box mb-3">
                        <span>{state.task}</span>
                        {state.taskAux
                            && <span className="text-secondary"> {state.taskAux}</span>
                        }
                    </div>
                    <div className="task-box mt-3">
                        <span style={{ whiteSpace: 'pre-wrap' }}>{state.maskedSolution}</span>

                        {state.maskedSolutionAux &&
                            <span className="text-secondary"> {state.maskedSolutionAux}</span>
                        }
                    </div>
                </div>

                <div className="mt-3 hint-reveal-panel">
                    {!trainingState.isSolutionShown ?
                        <div className="row g-2 g-sm-3">
                            <div className={state.example ? "col-6 col-sm-4" : "col-12 col-sm-6"}>
                                <Button variant="light"
                                    size="lg"
                                    onClick={getHint}>
                                    Hint
                                </Button>
                            </div>

                            <div className={state.example ? "col-6 col-sm-4" : "d-none"}>
                                <Button variant="light"
                                    size="lg"
                                    onClick={showExample}
                                    disabled={!!state.isExampleShown}
                                    className="px-1">
                                    Show example
                                </Button>
                            </div>

                            <div className={state.example ? "col-12 col-sm-4" : "col-12 col-sm-6"}>
                                <Button variant="light"
                                    size="lg"
                                    onClick={showSolution}>
                                    Show answer
                                </Button>
                            </div>
                        </div>
                        :
                        <InterpretAssess assess={assess} />
                    }
                    {(trainingState.isSolutionShown || state.isExampleShown) && state.example &&
                        <div className="task-box mt-3">
                            <div className="text-secondary" style={{ whiteSpace: 'pre-wrap' }}>{state.example}</div>
                        </div>
                    }
                    {trainingState.isSolutionShown && state.similar &&
                        <ul className="list-unstyled mt-2 mb-0 fs-5">
                            {state.similar.map(x =>
                                <li key={x.id}><span className="fw-bold">
                                    {x.challenge}</span>{x.dictionaryName ? ` (${x.dictionaryName})` : ``} - {x.answer}
                                </li>
                            )}
                        </ul>
                    }
                </div>
            </div>
        </>
    );
}
