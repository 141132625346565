import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { useLongPress } from 'use-long-press';
import { Variant } from '../../types/TrainingTypes';

const Styles = styled.div`
.btn-light {    
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.1;
    padding: 0.45rem 0.3rem 0.6rem;
}`

const threshold = 500;
const cancelOnMovement = 5;

export default function ChoiceVariants(props: {
    variants: Variant[],
    backgrounds: string[],
    chooseVariant: (x: Variant | undefined, isLong: boolean) => void,
    showUnknown?: boolean,
}) {
    const { variants, backgrounds, chooseVariant, showUnknown } = props;
    const longPress0 = useLongPress(() => { }, {
        threshold,
        cancelOnMovement,
        onFinish: x => x.type === 'pointerup' && chooseVariant(variants[0], true),
    });
    const longPress1 = useLongPress(() => { }, {
        threshold,
        cancelOnMovement,
        onFinish: x => x.type === 'pointerup' && chooseVariant(variants[1], true),
    });
    const longPress2 = useLongPress(() => { }, {
        threshold,
        cancelOnMovement,
        onFinish: x => x.type === 'pointerup' && chooseVariant(variants[2], true),
    });
    const longPress3 = useLongPress(() => { }, {
        threshold,
        cancelOnMovement,
        onFinish: x => x.type === 'pointerup' && chooseVariant(variants[3], true),
    });
    const longPressNoVariant = useLongPress(() => { }, {
        threshold,
        cancelOnMovement,
        onFinish: x => x.type === 'pointerup' && chooseVariant(undefined, true),
    });

    return (
        <Styles className="row g-2 g-sm-3 my-3">
            <div className="col-12 col-sm-6">
                <Button variant="light"
                    size="lg"
                    className={backgrounds[0]}
                    onClick={() => chooseVariant(variants[0], false)}
                    {...longPress0()}>

                    {variants[0].content}
                    {variants[0].contentAux &&
                        <span className="fs-3 text-secondary"> {variants[0].contentAux}</span>}
                </Button>
            </div>

            <div className="col-12 col-sm-6">
                <Button variant="light"
                    size="lg"
                    className={backgrounds[1]}
                    onClick={() => chooseVariant(variants[1], false)}
                    {...longPress1()}>

                    {variants[1].content}
                    {variants[1].contentAux &&
                        <span className="fs-3 text-secondary"> {variants[1].contentAux}</span>}
                </Button>
            </div>

            <div className="col-12 col-sm-6">
                <Button variant="light"
                    size="lg"
                    className={backgrounds[2]}
                    onClick={() => chooseVariant(variants[2], false)}
                    {...longPress2()}>

                    {variants[2].content}
                    {variants[2].contentAux &&
                        <span className="fs-3 text-secondary"> {variants[2].contentAux}</span>}
                </Button>
            </div>

            <div className="col-12 col-sm-6">
                <Button variant="light"
                    size="lg"
                    className={backgrounds[3]}
                    onClick={() => chooseVariant(variants[3], false)}
                    {...longPress3()}>

                    {variants[3].content}
                    {variants[3].contentAux &&
                        <span className="fs-3 text-secondary"> {variants[3].contentAux}</span>}
                </Button>
            </div>

            {showUnknown === true &&
                <div className="col-12 col-sm-6 mx-sm-auto">
                    <Button variant="outline-secondary"
                        size="lg"
                        className={backgrounds[4] ?? ''}
                        onClick={() => chooseVariant(undefined, false)}
                        {...longPressNoVariant()}>
                        My guess was wrong
                    </Button>
                </div>
            }
        </Styles>
    );
}
