import * as React from 'react';
import { Form } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useFetchOperation } from '../../service/Operation';
import Alerts from '../general/Alerts';
import Error from '../general/Error';
import Loader from '../general/Loader';
import ProgressButton from '../general/ProgressButton';

interface RolePage {
    userId: number,
    userName: string,
    role: string,
    availableRoles: string[]
}

export default function RoleEdit() {
    const params = useParams();
    const [data, setData] = React.useState<RolePage>();

    const [getting, startGetting] = useFetchOperation(onGettingSuccess, undefined, true);
    const [editing, startEditing, resetEditing] = useFetchOperation(() => { setTimeout(resetEditing, 2000) });

    React.useEffect(() => {
        startGetting('get', `api/role/${params.id}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onGettingSuccess(data: RolePage) {
        setData(data);
        document.title = `Edit role - ${data.userName} - ${(document as any).rootTitle}`;
    }

    function onRoleChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setData({ ...data!, role: e.target.value });
    }

    function onEditClick() {
        const body = { userId: data!.userId, role: data!.role };
        startEditing('put', `api/role`, body);
    }


    return (
        <>
            {getting.active
                ?
                <Loader />
                :
                !data
                    ?
                    <Error text={getting.error} />
                    :
                    <div>
                        <h1>
                            <span>Edit role for </span>
                            <Link to={`/user/${data.userId}`}>{data.userName}</Link>
                        </h1>

                        <Form>
                            <Form.Select id="challengeLanguageId"
                                value={data.role}
                                onChange={onRoleChange}
                                style={{ width: '150px' }}
                                className="mx-auto" >
                                {data.availableRoles.map(l =>
                                    <option value={l} key={l}>{l}</option>)}
                            </Form.Select>

                            <ProgressButton className="my-4 d-block mx-auto btn-wide"
                                onClick={onEditClick}
                                active={editing.active}
                                success={editing.success} />
                        </Form>

                        <Alerts error={editing.error}
                            onClose={() => resetEditing()} />
                    </div>
            }
        </>
    )
}
