import React, { ChangeEvent } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useFetchOperation } from '../../service/Operation';
import { useAppDispatch, useAppSelector } from '../../store';
import { addingActionCreators } from '../../store/Adding';
import { CardHandler, AddCardsResponse, DictionaryAddingCards } from '../../types/Common';
import Error from '../general/Error';
import Loader from '../general/Loader';
import ProgressButton from '../general/ProgressButton';
import TabTextarea from '../general/TabTextarea';
import Alerts from './../general/Alerts';
import CardAddWarning from './CardAddWarning';
import DicSelectPanel from './DicSelectPanel';

interface AddCardsPage {
    dictionaries: DictionaryAddingCards[],
    dictionaryId: number,
    cardHandlers: CardHandler[]
}

export default function CardAdd() {
    const cardsContent = useAppSelector((state) => state.adding.cardsContent);
    const dispatch = useAppDispatch();
    const params = useParams();

    const [dictionaries, setDictionaries] = React.useState<DictionaryAddingCards[]>();
    const [dictionaryId, setDictionaryId] = React.useState<number | undefined>();
    const [cardHandlers, setCardHandlers] = React.useState<CardHandler[]>();
    const [cardHandlerId, setCardHandlerId] = React.useState<number | undefined>();

    const [addingResult, setAddingResult] = React.useState<AddCardsResponse>();

    const [getting, startGetting] = useFetchOperation(onGettingSuccess, undefined, true);

    const [adding, startAdding, resetAdding] = useFetchOperation(onAddSuccess);

    React.useEffect(() => {
        let url = `api/cards/add`;
        if (params.id)
            url += `?dictionaryId=${params.id}`
        startGetting('get', url);
        document.title = `Add cards - ${(document as any).rootTitle}`;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);

    function onGettingSuccess(data: AddCardsPage) {
        setDictionaries(data.dictionaries);
        setDictionaryId(data.dictionaryId);
        setCardHandlers(data.cardHandlers);
        setCardHandlerId((data.dictionaries.find(x => x.id === data.dictionaryId))?.cardHandlerId);
    }

    function onClick() {
        const body = {
            dictionaryId,
            cardHandlerId: cardHandlerId ? cardHandlerId : undefined,
            cardsContent
        };
        startAdding('post', `api/cards/add`, body);
    }

    function onAddSuccess(data: AddCardsResponse) {
        setAddingResult(data);
        if (data?.isLimitExceeded)
            return "You can't add more than 100 cards on free account.";
        else if (data?.addedCardsCount)
            dispatch(addingActionCreators.setCardsContent(''));
        else
            return "No cards added";
    }

    function onTextChange(newText: string) {
        dispatch(addingActionCreators.setCardsContent(newText));
    }
    function onDictionaryChange(dictionaryId: number) {
        setDictionaryId(dictionaryId);
        setCardHandlerId((dictionaries?.find(x => x.id === dictionaryId))?.cardHandlerId);
    }
    function onHandlerChange(e: ChangeEvent<HTMLSelectElement>) {
        setCardHandlerId(parseInt(e.target.value));
    }

    return (
        <>
            {getting.active ?
                <Loader />
                :
                getting.error ?
                    <Error text={getting.error} />
                    :
                    <div className="width-limit">
                        <h1>Add cards</h1>

                        <CardAddWarning
                            duplicates={addingResult?.duplicates}
                            invalidLines={addingResult?.invalidLines}
                            openInNew={!!cardsContent} />

                        <Form>
                            <DicSelectPanel
                                dictionaries={dictionaries}
                                dictionaryId={dictionaryId}
                                onDictionaryChange={onDictionaryChange}
                                openInNew={!!cardsContent} />

                            <TabTextarea text={cardsContent}
                                onChange={onTextChange}
                                placeholder="Lines in the format:
Task phrase + tab + meaning phrase + [tab + additional task phrase (transcription/conjugation)] + [tab + example]

*optional fields are in brackets

*equals character '=' is accepted instead of tab"/>

                            <InputGroup className="my-3">
                                <InputGroup.Text className="px-1 px-sm-2" >
                                    <label htmlFor="handler">Beautifier</label>
                                </InputGroup.Text>

                                <Form.Select
                                    id="handler"
                                    value={cardHandlerId ?? ''}
                                    onChange={onHandlerChange}>
                                    <option></option>
                                    {cardHandlers?.map(d =>
                                        <option value={d.id} key={d.id}>{d.name}</option>)}
                                </Form.Select>
                            </InputGroup>

                            <ProgressButton
                                className="mt-4 d-block mx-auto btn-wide"
                                active={adding.active}
                                success={adding.success}
                                text="Add"
                                onClick={onClick} />
                        </Form>

                        <Alerts success={adding.success}
                            error={adding.error}
                            onClose={() => resetAdding()}
                            successContent={`Successfully added ${addingResult?.addedCardsCount} cards. Total: ${addingResult?.totalCards} cards.`} />
                    </div>
            }
        </>
    )
}
