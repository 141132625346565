import { ChangeEvent } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DictionarySlim } from '../../types/Common';

export default function DicSelectPanel(props: {
    dictionaries: DictionarySlim[] | undefined,
    dictionaryId: number | undefined,
    onDictionaryChange: (d: number) => void,
    openInNew?: boolean
}) {
    function onDictionaryChange(event: ChangeEvent<HTMLSelectElement>) {
        const value = event.target.value;
        props.onDictionaryChange(parseInt(value));
    }

    return (
        <InputGroup className="my-3">
            <InputGroup.Text className="px-1 px-sm-2" >
                <label htmlFor="dictionaryId">Dictionary</label>
            </InputGroup.Text>

            <Form.Select
                id="dictionaryId"
                value={props.dictionaryId}
                onChange={onDictionaryChange}>
                {props.dictionaries?.map(d =>
                    <option value={d.id} key={d.id}>{d.name}</option>)}
            </Form.Select>

            <Link className="btn btn-outline-primary px-1 px-sm-2"
                to={`/dictionaries/${props.dictionaryId}`}
                target={props.openInNew ? '_blank' : undefined}>
                Open
            </Link>
        </InputGroup>
    )
}
