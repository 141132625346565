import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../store';
import dictionariesActions from '../../store/Dictionaries';

export default function Select() {
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const auth = useAppSelector(state => state.auth);
    const dictionariesStore = useAppSelector(state => state.dictionaries);
    const choiceTrainingAvailable = useAppSelector(state => state.stat?.modeStat?.choiceTrainingAvailable) || !auth.user;
    const isPreview = searchParams.get('preview') === 'true';

    React.useEffect(() => {
        document.title = (document as any).rootTitle;
        if (!auth.user && dictionariesStore.dictionaries?.length === 1)
            dispatch(dictionariesActions.getDictionaries());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onDictionaryChange(e: ChangeEvent<HTMLSelectElement>) {
        dispatch(dictionariesActions.select(parseInt(e.target.value)));
    }

    return (
        <div className="width-limit">
            {isPreview &&
                <h5>Preview of new cards</h5>
            }

            {!auth.user &&
                <>
                    <p><Link to="signin" className="btn btn-sm btn-outline-primary me-1">Sign in</Link> to save your progress</p>

                    <Form.Select
                        id="dictionaryId"
                        name="dictionaryId"
                        value={dictionariesStore.dictionaryId}
                        className="my-4 mx-auto"
                        style={{ maxWidth: '400px' }}
                        onChange={onDictionaryChange}>
                        {dictionariesStore.dictionaries?.map(d =>
                            <option value={d.id} key={d.id}>{d.name}</option>)}
                    </Form.Select>
                </>
            }

            <div className="row g-3 mb-3">
                <div className="col-12 col-md-6">
                    <Link to='/interpret'
                        className="text-decoration-none btn btn-light btn-lg">
                        <h2>Study and review</h2>
                        <p className="fs-5">In this mode you assess how well you know the phrase. Next cards will be shown depending on these assessments, starting with your weakest cards</p>
                    </Link>
                </div>

                <div className="col-12 col-md-6">
                    <Link to='/choice'
                        className={'text-decoration-none btn btn-light btn-lg' + (choiceTrainingAvailable ? '' : ' disabled')}>
                        <h2>Choose the right variant</h2>
                        <p className="fs-5">In this mode you choose the suitable meaning of the given phrase from four variants. Only cards already passed in "Study and review" mode will be shown</p>
                    </Link>
                </div>

                {auth.user &&
                    <>
                        <div className="col-12 col-md-6">
                            <Link to='/combined'
                                className={'text-decoration-none btn btn-light btn-lg' + (choiceTrainingAvailable ? '' : ' disabled')}>
                                <h2>Combined</h2>
                                <p className="fs-5">You try to guess the meaning like in training "Study and review", then choose a meaning from four variants like in "Choose the right variant"</p>
                            </Link>
                        </div>

                        <div className="col-12 col-md-6">
                            <Link to='/auto'
                                className={'text-decoration-none btn btn-light btn-lg' + (choiceTrainingAvailable ? '' : ' disabled')}>
                                <h2>Automatic</h2>
                                <p className="fs-5">The type of training ("Study and review" or "Choose the right variant") will be automatically selected depending on the time period from last review of the cards</p>
                            </Link>
                        </div>

                        <div className="col-12 col-md-6">
                            <Link to='/preview'
                                className="text-decoration-none btn btn-light btn-lg">
                                <h2>Preview of new cards</h2>
                                <p className="fs-5">You preview not studied cards and give them knowledge assessments, so that the further studying in "Study and Review" will begin from the most easiest cards</p>
                            </Link>
                        </div>

                        <div className="col-12 col-md-6">
                            <Link to='/hard'
                                className="text-decoration-none btn btn-light btn-lg">
                                <h2>Review of hard cards</h2>
                                <p className="fs-5">In this mode you review the cards in on-hold state and cards that have been viewed many times, but have not yet been memorized well</p>
                            </Link>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}
