import React, { ChangeEvent } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { useFetchOperation } from '../../../service/Operation';
import { Card, EditCard, CardPage } from '../../../types/Common';
import Error from '../../general/Error';
import Loader from '../../general/Loader';
import useOnChange from '../../general/OnChange';
import { useAppSelector } from '../../../store';
import ActionsPanel from './ActionsPanel';
import AddToStudying from './AddToStudying';
import ExternalLinks from './ExternalLinks';
import HandlingPanel from './HandlingPanel';

export default function CardEdit() {
    const params = useParams();
    const auth = useAppSelector(state => state.auth);

    const [pageData, setPageData] = React.useState<CardPage>();
    const [card, setCard] = React.useState<EditCard>();
    const [deleted, setDeleted] = React.useState(false);
    const [handlingActive, setHandlingActive] = React.useState(false);

    const [getting, startGetting] = useFetchOperation(onGettingSuccess, undefined, true);
    const { onStringChange, onBooleanChange } = useOnChange(card, setCard);
    const [modalShow, setModalShow] = React.useState(false);

    React.useEffect(() => {
        startGetting('get', `api/cards/${params.id}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function updateCardProps(newCard: Card) {
        setCard({ ...card!, ...newCard })
    }

    function onGettingSuccess(data: CardPage) {
        setPageData(data);
        setCard(data.card);
        document.title = `${data.card.challenge} - Edit card - ${(document as any).rootTitle}`;
    }

    function toggleHandlingActive() {
        setHandlingActive(!handlingActive);
    }

    function onCardHandlerIdChange(cardHandlerId: number | undefined) {
        setPageData({ ...pageData!, cardHandlerId });
    }

    function onSkippedChange(event: ChangeEvent<HTMLInputElement>): void {
        var newValue = event.target.checked;
        if (!newValue)
            setCard({ ...card!, skipped: false })
        else
            setModalShow(true);
    }

    function onSkipConfirmed() {
        setCard({ ...card!, skipped: true });
        setModalShow(false);
    }

    return <>
        {getting.active ?
            <Loader />
            :
            !pageData || !card ?
                <Error text={getting.error} />
                :
                <div className="width-limit">
                    <h1>{pageData.isEditable ? 'Edit card' : 'View card'}</h1>

                    <div className="btn-panel">
                        <Link to={`/dictionaries/${pageData.dictionaryId}`}
                            className="btn btn-outline-primary btn-sm">
                            Dictionary {pageData.dictionaryName}
                        </Link>

                        <Link to={`/cards/${card.id}/similar`}
                            className="btn btn-outline-primary btn-sm">
                            Similar
                        </Link>

                        <Link to={`/cards/${card.id}/results`}
                            className="btn btn-outline-primary btn-sm">
                            Results
                        </Link>

                        <AddToStudying
                            userId={auth.user!.id}
                            cardId={card.id}
                            isDirect={true}
                            disabled={deleted} />
                    </div>

                    {pageData.dictionaryPaths.challengeLanguageCode &&
                        <div className="btn-panel mb-0">
                            <ExternalLinks card={card}
                                modeInfo={pageData.dictionaryPaths}
                                isExtended />
                        </div>
                    }

                    <Form className="form-large">

                        <Form.Group controlId="challenge">
                            <Form.Label>Task phrase</Form.Label>
                            <Form.Control as={TextareaAutosize}
                                value={card.challenge}
                                onChange={onStringChange}
                                readOnly={!pageData.isEditable || deleted}
                                style={{ resize: 'none' }} />
                        </Form.Group>

                        <Form.Group controlId="challengeAux">
                            <Form.Label>Additional task phrase</Form.Label>
                            <Form.Control as={TextareaAutosize}
                                value={card.challengeAux ?? ''}
                                onChange={onStringChange}
                                readOnly={!pageData.isEditable || deleted}
                                style={{ resize: 'none' }} />
                        </Form.Group>

                        <Form.Group controlId="answer">
                            <Form.Label >Answer</Form.Label>
                            <Form.Control as={TextareaAutosize}
                                value={card.answer}
                                onChange={onStringChange}
                                readOnly={!pageData.isEditable || deleted}
                                style={{ resize: 'none' }} />
                        </Form.Group>

                        <Form.Group controlId="example">
                            <Form.Label >Example</Form.Label>
                            <Form.Control as={TextareaAutosize}
                                value={card.example ?? ''}
                                onChange={onStringChange}
                                readOnly={!pageData.isEditable || deleted}
                                style={{ resize: 'none' }} />
                        </Form.Group>

                        {pageData.isEditable &&
                            <>
                                <Form.Group controlId="marked">
                                    <Form.Check type="checkbox"
                                        label="Mark for checking"
                                        className="text-start mt-1"
                                        checked={card.marked && !deleted}
                                        onChange={onBooleanChange} />
                                </Form.Group>

                                <Form.Group controlId="skipped">
                                    <Form.Check type="checkbox"
                                        label="Skipped"
                                        className="text-start mt-1"
                                        checked={card.skipped && !deleted}
                                        onChange={onSkippedChange} />
                                </Form.Group>
                            </>
                        }
                    </Form>

                    {pageData.isEditable &&
                        <>
                            {handlingActive ?
                                <HandlingPanel
                                    card={card}
                                    cardHandlerId={pageData.cardHandlerId}
                                    cardHandlers={pageData.cardHandlers}
                                    updateCard={updateCardProps}
                                    onCardHandlerIdChange={onCardHandlerIdChange}
                                    toggleHandlingActive={toggleHandlingActive} />
                                :
                                <ActionsPanel card={card}
                                    updateCard={updateCardProps}
                                    deleted={deleted}
                                    setDeleted={setDeleted}
                                    toggleHandlingActive={toggleHandlingActive} />
                            }
                        </>
                    }

                    <Modal show={modalShow} onHide={() => setModalShow(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <p className="fw-bold">Are you sure you want to skip studying of this card and delete its result if exists?</p>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setModalShow(false)}>Cancel</Button>
                            <Button variant="danger"
                                onClick={onSkipConfirmed}>
                                Skip
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </div>
        }
    </>
}
