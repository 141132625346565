import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { trainingActionCreators } from '../../store/Training';
import { TrainingType } from '../../types/TrainingTypes';
import Loader from '../general/Loader';
import Choice from './Choice';
import Combined from './Combined';
import Interpret from './Interpret';
import ServicePanel from './ServicePanel';
import TrainingOptions from './TrainingOptions';

interface TrainingProps {
    trainingType: TrainingType;
    isPreview?: boolean;
    isHard?: boolean;
}

export default function Training(props: TrainingProps) {
    const { trainingType, isPreview, isHard } = props;
    const trainingState = useAppSelector(state => state.training);
    const guestDictionaryId = useAppSelector(state => state.dictionaries).dictionaryId;
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(trainingActionCreators.start(trainingType, isPreview ?? false, isHard ?? false, guestDictionaryId));
        document.title = `Training - ${(document as any).rootTitle}`;
    }, [dispatch, trainingType, isPreview, guestDictionaryId]);

    return (
        <>
            {!trainingState.card ?
                <Loader />
                :
                <>
                    <div className="flex-grow-1 d-flex flex-column align-items-center w-100"
                        style={{ paddingTop: 'calc(30vh - 12.5rem)' }} >
                        {trainingState.card.trainingType === TrainingType.Interpret &&
                            <Interpret />
                        }
                        {trainingState.card.trainingType === TrainingType.Choice &&
                            <Choice />
                        }
                        {trainingState.card.trainingType === TrainingType.Combined &&
                            <Combined />
                        }
                    </div>

                    {trainingState.isSolutionShown && trainingState.isServicePanelEnabled
                        ?
                        <ServicePanel card={trainingState.card} />
                        :
                        <TrainingOptions card={trainingState.card} />
                    }
                </>
            }
        </>
    );
}
