import { Form, FormGroup, InputGroup } from 'react-bootstrap';
import { CardHandler, DictionaryData, Language } from '../../types/Common';
import useOnChange from '../general/OnChange';
import { Link } from 'react-router-dom';

export default function DicEditor(props: {
    dictionary: DictionaryData,
    languages: Language[],
    cardHandlers?: CardHandler[],
    onChange(data: DictionaryData): void
}) {
    const { onStringChange, onNumberChange, onBooleanChange } = useOnChange(props.dictionary, props.onChange);

    return (
        <>
            <FormGroup>
                <Form.Label htmlFor="name">Name</Form.Label>
                <Form.Control id="name"
                    value={props.dictionary.name || ''}
                    onChange={onStringChange} />
            </FormGroup>

            <div className="my-3">
                <FormGroup>
                    <Form.Label htmlFor="challengeLanguageId">Challenge language</Form.Label>

                    <InputGroup>
                        <Form.Select id="challengeLanguageId"
                            value={props.dictionary.challengeLanguageId || 0}
                            onChange={onNumberChange}>
                            <option></option>
                            {props.languages.map(l =>
                                <option value={l.id} key={l.id}>{l.name}</option>)}
                        </Form.Select>

                        <Link className="btn btn-sm btn-outline-primary px-1 px-sm-2"
                            to={`/languages/${props.dictionary.challengeLanguageId || ''}`}>
                            Open
                        </Link>
                    </InputGroup>

                </FormGroup>

                <FormGroup>
                    <Form.Label htmlFor="answerLanguageId">Answer language</Form.Label>

                    <InputGroup>
                        <Form.Select id="answerLanguageId"
                            value={props.dictionary.answerLanguageId || 0}
                            onChange={onNumberChange}>
                            <option></option>
                            {props.languages.map(l =>
                                <option value={l.id} key={l.id}>{l.name}</option>)}
                        </Form.Select>

                        <Link className="btn btn-sm btn-outline-primary px-1 px-sm-2"
                            to={`/languages/${props.dictionary.answerLanguageId || ''}`}>
                            Open
                        </Link>
                    </InputGroup>
                </FormGroup>

                <FormGroup>
                    <Form.Label htmlFor="reversoToLanguageId">Reverso translation language</Form.Label>

                    <InputGroup>
                        <Form.Select id="reversoToLanguageId"
                            value={props.dictionary.reversoToLanguageId || 0}
                            onChange={onNumberChange}>
                            <option></option>
                            {props.languages.map(l =>
                                <option value={l.id} key={l.id}>{l.name}</option>)}
                        </Form.Select>

                        <Link className="btn btn-sm btn-outline-primary px-1 px-sm-2"
                            to={`/languages/${props.dictionary.reversoToLanguageId || ''}`}>
                            Open
                        </Link>
                    </InputGroup>
                </FormGroup>
            </div>

            <FormGroup>
                <Form.Label htmlFor="choiceTrainingAvailable">Mode 'Choose the right variant'</Form.Label>
                <Form.Select id="choiceTrainingAvailable"
                    value={props.dictionary.choiceTrainingAvailable.toString()}
                    onChange={onBooleanChange}>
                    <option value="true">available</option>
                    <option value="false">non-available</option>
                </Form.Select>
            </FormGroup>

            <FormGroup>
                <Form.Label htmlFor="onlyChallengeAuxAvailable">Display 'Task aux' without 'Task'</Form.Label>
                <Form.Select id="onlyChallengeAuxAvailable"
                    value={props.dictionary.onlyChallengeAuxAvailable.toString()}
                    onChange={onBooleanChange}>
                    <option value="true">available</option>
                    <option value="false">non-available</option>
                </Form.Select>
            </FormGroup>

            {props.cardHandlers &&
                <FormGroup>
                    <Form.Label htmlFor="cardHandlerId">Default card beautifier</Form.Label>
                    <Form.Select id="cardHandlerId"
                        value={props.dictionary.cardHandlerId || ''}
                        onChange={onNumberChange}>
                        <option></option>
                        {props.cardHandlers.map(l =>
                            <option value={l.id} key={l.id}>{l.name}</option>)}
                    </Form.Select>
                </FormGroup>}
        </>
    )
}
