import { ColumnDef } from '@tanstack/react-table';
import { Button, Dropdown } from 'react-bootstrap';
import { Chart } from 'react-google-charts';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useFetchOperation } from '../../../service/Operation';
import { useAppSelector } from '../../../store';
import { UserMode } from '../../../types/Common';
import { LocalDate, apiDateFormat } from '../../formatters/DateTimeFormat';
import AutoPaginationTable from '../../general/AutoPaginationTable';
import Error from '../../general/Error';
import Loader from '../../general/Loader';
import UserPanel from './../UserPanel';
import * as moment from 'moment';
import * as React from 'react';

interface UserHistoryPage {
    userMode: UserMode,
    history: DayStat[],
    periodsHistory: DayStat[]
}

interface DayStat {
    date: string,
    period: number | undefined,

    updations: number | undefined,
    avgStrength: number | undefined,
    weakDeltaSum: number | undefined,
    interpretShare: number | undefined,
    memorized: number | undefined,
    weak: number | undefined,
    medium: number | undefined,
    strong: number | undefined,
    onHold: number | undefined,
    studied: number,
    uniqueCardsUpdated: number,

    //calculated:
    memorizedPercent: number | undefined,
    notStrong: number | undefined,

    avgStrengthDelta: number | undefined,
    memorizedDelta: number | undefined,
    studiedDelta: number | undefined,
    updationsDelta: number | undefined,
    nonUniqueCardsUpdated: number | undefined,
}

export const historyPeriods: { [code: number]: string } = {
    0: 'Now',
    1: 'Today',
    2: '2 days',
    3: '3 days',
    4: '4 days',
    5: '5 days',
    6: '6 days',
    7: '1 week',
    14: '2 weeks',
    21: '3 weeks',
    30: '1 month',
    46: '1.5 months',
    61: '2 months',
    91: '3 months',
    122: '4 months',
    152: '5 months',
    183: '6 months',
    213: '7 months',
    244: '8 months',
    274: '9 months',
    304: '10 months',
    335: '11 months',
    365: '1 year',
    548: '1.5 years',
    731: '2 years',
    913: '2.5 years',
    1096: '3 years',
    1278: '3.5 years',
    1461: '4 years',
    1643: '4.5 years',
    1826: '5 years',
    2009: '5.5 years',
    2192: '6 years',
    2374: '6.6 years',
    2557: '7 years',
    2739: '7.5 years',
    2922: '8 years',
}

const breakdowns = {
    days: 'Daily',
    weeks: 'Weekly',
    periods: 'Periods',
}

type Breakdown = keyof typeof breakdowns;

export default function UserHistory() {
    const auth = useAppSelector(state => state.auth);
    const params = useParams();
    const contanerRef = React.useRef(null);
    const [searchParams] = useSearchParams();

    const [breakdown, setBreakdown] = React.useState<Breakdown>('days');
    const [pageData, setPageData] = React.useState<UserHistoryPage>();
    const [tableDays, setTableDays] = React.useState<DayStat[]>();
    const [chartDays, setChartDays] = React.useState<DayStat[]>();
    const [chartExpanded, setChartExpanded] = React.useState(false);
    const [updationsMaxValue, setUpdationsMaxValue] = React.useState<number>(100);

    const [getting, startGetting] = useFetchOperation(onGetSuccess, undefined, true);
    const darkTheme = window.matchMedia("(prefers-color-scheme: dark)")?.matches;

    const scrollRef = React.useRef<HTMLDivElement>(null);

    let chartWidth: number | undefined = undefined;
    if (pageData?.history && pageData?.history?.length >= 3) {
        const days = Math.abs(new Date(pageData.history[pageData.history.length - 1].date).getTime() - new Date(pageData.history[1].date).getTime()) / (1000 * 60 * 60 * 24);
        chartWidth = Math.floor(days * 3);
    }
    const showExpandButton = chartWidth! > (contanerRef.current as any)?.offsetWidth;

    React.useEffect(() => {
        const isDirect = searchParams.get('direct')?.toLowerCase() !== 'false';
        startGetting('get', `api/user/${params.id}/history?dictionaryId=${params.dictionaryId}&isDirect=${isDirect}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id, searchParams]);

    function onGetSuccess(data: UserHistoryPage) {

        if (data.history) {
            data.history.forEach(h => {
                h.memorizedPercent = h.memorized !== undefined ? h.memorized! / h.studied * 100 : 0;

                if (typeof (h.weak) === 'number')
                    h.notStrong = h!.weak! + h!.medium!;
            })
        }
        fillHistoryDelta(data.periodsHistory);

        const history = breakdown === 'days' ? data.history : getWeeklyHistory(data.history);
        if (history)
            fillHistoryDelta(history);

        setTableDays(history);
        setChartDays(history);

        let max = 100;
        for (let i = 0; i < history.length; i++)
        {
            if (typeof history[i].updationsDelta === 'number') {
                if (history[i].updationsDelta! > max)
                    max = history[i].updationsDelta!;
            }
        }
        setUpdationsMaxValue(max);

        if (data.periodsHistory) {
            fillPeriodsHistoryDelta(data.periodsHistory);
            data.periodsHistory = data.periodsHistory.filter(x => x.studied > 0);
        }
        setPageData(data);
        document.title = `${data.userMode.userName} - ${data.userMode.dictionaryName} ${data.userMode.isDirect ? '' : '(inverse) '}- History - ${(document as any).rootTitle}`;
    }

    function onBreakdownChange(breakdown: Breakdown) {
        setBreakdown(breakdown);

        let newTableHistory: DayStat[] | undefined = pageData!.history;
        let newChartHistory: DayStat[] | undefined = pageData!.history;

        if (breakdown === 'weeks')
            newChartHistory = newTableHistory = getWeeklyHistory(pageData!.history);
        else if (breakdown === 'periods') {
            newTableHistory = pageData?.periodsHistory;
            newChartHistory = pageData!.history
        }

        if (newTableHistory)
            fillHistoryDelta(newTableHistory);

        setTableDays(newTableHistory);
        setChartDays(newChartHistory);
    }

    function getWeeklyHistory(days: DayStat[]) {
        let lastWeek;
        let weeklyHistory: DayStat[] = [];

        for (let i = days.length - 1; i >= 0; i--) {
            var current = days[i];
            var m = moment.utc(current.date);
            var week = m.isoWeekYear() * 1000 + m.isoWeek();
            if (week !== lastWeek)
                weeklyHistory.push(days[i]);
            lastWeek = week;
        }
        weeklyHistory.reverse();
        return weeklyHistory;
    }

    function fillHistoryDelta(history: DayStat[]) {
        //let maxUd = 1;
        for (let i = 0; i < history.length; i++) {
            fillDelta(history[i], history[i + 1]);

        }
        const a1: number | undefined = undefined;
        const a2: number | undefined = 123;
        console.log(a1! > 0)
        console.log(a2! > 0)


        //    const maxUd = history.reduce((p, c) => p.updationsDelta! > c.updationsDelta! ? p : c).updationsDelta!;
        //    setUpdationsMaxValue(maxUd);
    }

    function fillPeriodsHistoryDelta(history: DayStat[]) {
        const modeStat = history[0];

        for (let i = 0; i < history.length; i++) {
            history[i].memorizedPercent = history[i].memorized && history[i].studied ? history[i].memorized! / history[i].studied * 100 : 0;
            if (i > 0)
                fillDelta(modeStat, history[i]);
        }
    }

    function fillDelta(stat1: DayStat, stat2: DayStat) {

        if (typeof (stat1.updations) === 'number' && stat2 && typeof stat2.updations === 'number') {
            stat1.updationsDelta = Math.max(0, stat1.updations! - ((stat2 && typeof stat2.updations === 'number') ? stat2.updations! : 0));

            stat1.nonUniqueCardsUpdated = stat1.updationsDelta! - stat1.uniqueCardsUpdated;
        }

        if (typeof (stat1.avgStrength) === 'number' && stat2 && typeof stat2.avgStrength === 'number')
            stat1.avgStrengthDelta = stat1.avgStrength! - stat2.avgStrength!;

        if (typeof (stat1.memorized) === 'number' && stat2 && typeof stat2.memorized === 'number')
            stat1.memorizedDelta = stat1.memorized! - stat2.memorized!;

        if ((typeof stat1.studied === 'number'))
            stat1.studiedDelta = stat1.studied! - ((stat2 && typeof stat2.studied === 'number') ? stat2.studied! : 0);
    }

    function expandChart() {
        setChartExpanded(!chartExpanded);
        setTimeout(function () {
            if (scrollRef.current)
                scrollRef.current.scrollLeft = scrollRef.current.scrollWidth;
        }, 1);
    }

    const options: any = React.useMemo(() => ({
        hAxis: {
            titleTextStyle: { italic: false },
            textStyle: { fontSize: 14 }
        },
        vAxes: [
            {
                title: "Cards",
                titleTextStyle: { italic: false, fontSize: 16 },
                minValue: 0,
                textStyle: { fontSize: 14 }
            },
            {
                maxValue: 1,
                minValue: 0,
                gridlines: { count: 0 },
                textPosition: 'none',
                textStyle: { fontSize: 14 }
            },
            {
                title: "Updations",
                titleTextStyle: { italic: false, fontSize: 16 },
                gridlines: { count: 0 },
                textStyle: { fontSize: 14 },
                minValue: 0,
                maxValue: updationsMaxValue,
            },
            {
                minValue: 0,
                maxValue: updationsMaxValue,
                gridlines: { count: 0 },
                textPosition: 'none',
            },
        ],
        bar: { groupWidth: '80%' },
        isStacked: true,
        legend: { position: 'bottom', textStyle: { fontSize: 14 } },
        fontName: 'Segoe UI',
        chartArea: { bottom: 50, top: 8, left: 60, right: 60 },
        seriesType: 'line',
        series: {
            0: { type: 'bars', targetAxisIndex: 2 },
            1: { type: 'bars', targetAxisIndex: 3 },
            2: { targetAxisIndex: 1, lineDashStyle: [1, 1] },
            3: { targetAxisIndex: 1, lineDashStyle: [4, 4] },
            5: { lineDashStyle: [20, 3] },
            8: { lineDashStyle: [20, 3] },
        },
        colors: ['#aaa', '#333', '#888', '#ffaaaa', '#3366cc', '#c300c3', '#ff6363', '#ff8200', '#009a00'],
        backgroundColor: darkTheme ? '#1d1d1d' : 'white',
        height: '450px',
        width: chartExpanded && chartWidth ? chartWidth + 'px' : '100%'
    }), [darkTheme, chartExpanded, updationsMaxValue]);

    const isDirect = searchParams.get('direct')?.toLowerCase() !== 'false';
    let search = '';
    if (!isDirect)
        search += `&direct=false`

    const columns = React.useMemo<ColumnDef<DayStat>[]>(() => [
        {
            header: 'Date',
            accessorKey: 'date',
            cell: props => typeof props.row.original.period === 'number'
                ? historyPeriods[props.row.original.period]
                : auth.isAdmin
                    ? <Link to={`${moment.utc(props.row.original.date).format(apiDateFormat)}?${search}`}><LocalDate value={props.row.original.date} /></Link>
                    : props.row.original.date
        },
        {
            header: 'In studying',
            accessorKey: 'studied',
            cell: (props) => <span>{props.row.original.studied}{(typeof (props.row.original.studiedDelta) == 'number') ? ' (' + (props.row.original.studiedDelta > 0 ? '+' : '') + props.row.original.studiedDelta + ')' : ''}</span>
        },
        {
            header: 'Memorized',
            accessorKey: 'memorized',
            cell: (props) => (typeof (props.row.original.memorized) == 'number') &&
                <span>{props.row.original.memorized!}&thinsp;{(typeof (props.row.original.memorizedDelta) == 'number') ? '(' + (props.row.original.memorizedDelta! > 0 ? '+' : '') + props.row.original.memorizedDelta! + ') ' : '• '}{props.row.original.memorizedPercent?.toFixed()}%</span>
        },
        {
            header: 'Reviews',
            accessorKey: 'updations',
            cell: props => (typeof (props.row.original.updations) == 'number') &&
                <span>{props.row.original.updations!} {(typeof (props.row.original.updationsDelta) == 'number') ? '(' + (props.row.original.updationsDelta! > 0 ? '+' : '') + props.row.original.updationsDelta! + ') ' : ''}</span>
        },
        {
            header: 'Average strength',
            accessorKey: 'avgStrength',
            cell: props => (typeof (props.row.original.avgStrength) == 'number') &&
                <span>{props.row.original.avgStrength?.toFixed(4)} {props.row.original.avgStrengthDelta! ? '(' + (props.row.original.avgStrengthDelta! > 0 ? '+' : '') + props.row.original.avgStrengthDelta?.toFixed(4) + ')' : ''}</span>
        },
        {
            header: 'Strong+​medium+​​weak+on hold',
            accessorKey: 'strong',
            cell: props => (typeof (props.row.original.strong) == 'number') &&
                <span>{props.row.original.strong!}+{props.row.original.medium!}+{props.row.original.weak!} ({props.row.original.weakDeltaSum?.toFixed(0)})+{props.row.original.onHold!}</span>
        },
        {
            header: 'Study : Choice',
            accessorKey: 'interpretShare',
            cell: props => (typeof (props.row.original.interpretShare) == 'number')
                ? <span>{(props.row.original.interpretShare * 100).toFixed(1)}%&thinsp;:&thinsp;{((1 - props.row.original.interpretShare) * 100).toFixed(1)}%</span>
                : <span></span>
        }
    ], []);

    return (
        <>
            <div ref={contanerRef} className="w-100 d-flex flex-column"></div>

            <UserPanel userMode={pageData?.userMode} />

            {getting.active ?
                <Loader />
                :
                getting.error ?
                    <Error text={getting.error} />
                    :
                    !tableDays || !chartDays ?
                        <p className="my-3">No results found</p>
                        :
                        <div className="w-100">
                            <h2>Timeline
                                {showExpandButton &&
                                    <Button size="sm"
                                        variant="outline-secondary"
                                        onClick={expandChart}
                                        className="ms-3 my-1">
                                        {chartExpanded ? 'Collapse' : 'Expand'}
                                    </Button>
                                }
                            </h2>

                            <div style={{ overflowY: 'hidden', overflowX: 'auto', width: '100%', minHeight: '450px', scrollBehavior: 'smooth' }}
                                ref={scrollRef} >
                                ===========
                                <span>{updationsMaxValue}</span>
                                -----------
                                <Chart key={chartExpanded ? 1 : 0}
                                    chartType="ComboChart"
                                    data={[['date', 'Reviews', 'Last updated cards', 'Study:Choice', 'Strength', 'In studying', 'Memorized', 'Weak', 'Not strong', 'Strong'], ...chartDays.map(x =>
                                        [
                                            new Date(x.date).getTime() < 0 ? new Date() : new Date(x.date),

                                            x.updationsDelta,
                                            x.uniqueCardsUpdated,
                                            x.interpretShare,
                                            x.avgStrength,
                                            x.studied,
                                            x.memorized,
                                            x.weak,
                                            x.notStrong,
                                            x.strong,
                                        ])]}
                                    options={options} />
                            </div>


                            <div className="mt-2 mb-3">
                                <Dropdown className="mt-2 d-inline-block">

                                    <Dropdown.Toggle
                                        className="btn-wide" >
                                        {breakdowns[breakdown]}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {Object.entries(breakdowns).map(([key, value]) =>
                                            <Dropdown.Item as={Button}
                                                variant="outline"
                                                key={key}
                                                active={key === breakdown}
                                                onClick={() => onBreakdownChange(key as Breakdown)}>
                                                {value}
                                            </Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>

                                <span className="ms-2">{tableDays.length - 1} {breakdown} in the history</span>
                            </div>

                            <AutoPaginationTable className="mt-2 mb-3"
                                columns={columns}
                                data={tableDays} />
                        </div>
            }
        </>
    )
}
