import { ColumnDef } from '@tanstack/react-table';
import * as React from 'react';
import { Link, useLocation, useParams } from "react-router-dom";
import { useFetchOperation } from '../../service/Operation';
import { Card, Dictionary } from '../../types/Common';
import { LocalDateTime } from '../formatters/DateTimeFormat';
import AutoPaginationTable from '../general/AutoPaginationTable';
import Error from '../general/Error';
import Loader from '../general/Loader';

interface Mark extends Card {
    dictionaryId: number,
    dictionaryName: string,
    userId: number,
    userName: string,
    dateTime: string
}

export default function Marks() {
    const params = useParams();
    const dictionary = useLocation().state as Dictionary;

    const [marks, setMarks] = React.useState<Mark[]>();

    const [getting, startGetting] = useFetchOperation(setMarks, undefined, true);

    React.useEffect(() => {
        startGetting('get', `api/marks/${params.id ?? ''}`)
        const dictionaryName = dictionary ? ` - ${dictionary.name}` : '';
        document.title = `Marked cards${dictionaryName} - ${(document as any).rootTitle}`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id])

    return (
        <>
            {getting.active ?
                <Loader />
                :
                getting.error ?
                    <Error text={getting.error} />
                    :
                    <>
                        <h1 className='mb-3'>
                            Marked cards
                            {dictionary &&
                                <> of <Link to={`/dictionaries/${dictionary.id}`}>
                                    {dictionary.name}
                                </Link>
                                </>
                            }
                        </h1>
                        {!marks ?
                            <p className="my-3">No marked cards found</p>
                            :
                            <div className="w-100">
                                <div className="mb-2">{marks.length} marked cards</div>

                                <AutoPaginationTable className="mb-3"
                                    columns={columns}
                                    data={marks} />
                            </div>
                        }
                    </>
            }
        </>
    )
}

const columns: ColumnDef<Mark>[] = [
    {
        header: '#',
        accessorKey: 'id',
        cell: props => <Link to={`/cards/${props.row.original.id}`}>{props.row.index + 1}</Link>
    },
    {
        header: 'Task phrase',
        accessorKey: 'challenge',
    },
    {
        header: 'Additional task phrase',
        accessorKey: 'challengeAux',
    },
    {
        header: 'Answer',
        accessorKey: 'answer',
    },
    {
        header: 'Example',
        accessorKey: 'example',
    },
    {
        header: 'Dictionary',
        accessorKey: 'dictionaryName',
        cell: props => <Link to={`/dictionaries/${props.row.original.dictionaryId}`}>{props.row.original.dictionaryName}</Link>
    },
    {
        header: 'User',
        accessorKey: 'userName',
        cell: props => <Link to={`/user/${props.row.original.userId}`}>{props.row.original.userName}</Link>
    },
    {
        header: 'Date',
        accessorKey: 'dateTime',
        cell: props => <LocalDateTime value={props.row.original.dateTime} />
    },
];
