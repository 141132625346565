import { PaginationState, SortingState, Updater, functionalUpdate } from "@tanstack/react-table";
import * as React from "react";
import { useLocation, useSearchParams } from 'react-router-dom';

export const defaultPageSize = 100;

export function usePaginationAndSorting() {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const initPs = getPaginationAndSorting(searchParams);
    const [pagination, updatePagination] = React.useState<PaginationState>(initPs.pagination);
    const [sorting, updateSorting] = React.useState<SortingState>(initPs.sorting);

    React.useEffect(() => {
        const ps = getPaginationAndSorting(searchParams);
        if (ps.pagination.pageIndex !== pagination.pageIndex || ps.pagination.pageSize !== pagination.pageSize)
            updatePagination(ps.pagination);
        if (ps.sorting.length !== sorting.length ||
            (ps.sorting.length && sorting.length &&
                (ps.sorting[0].id !== sorting[0].id || ps.sorting[0].desc !== sorting[0].desc)))
            updateSorting(ps.sorting);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    function getBaseSearchParams() {
        const baseSearchParams: Record<string, string> = {};
        searchParams.forEach((val, key) => {
            if (!['page', 'size', 'sort', 'dir'].includes(key))
                baseSearchParams[key] = val;
        })
        return baseSearchParams;
    }

    const setPagination = (updater: Updater<PaginationState>) => {
        const newPagination = functionalUpdate(updater, pagination);
        const sp = getSearchParams(newPagination, sorting);
        setSearchParams({ ...getBaseSearchParams(), ...sp });
    }

    const setSorting = (updater: Updater<SortingState>) => {
        const newSorting = functionalUpdate(updater, sorting);
        const sp = getSearchParams({ pageIndex: 0, pageSize: pagination.pageSize }, newSorting);
        setSearchParams({ ...getBaseSearchParams(), ...sp });
    }

    return {
        pagination,
        sorting,
        setPagination,
        setSorting
    };
}

function getPaginationAndSorting(searchParams: URLSearchParams) {
    const page = searchParams.get("page");
    const size = searchParams.get("size");
    const sort = searchParams.get("sort");
    const dir = searchParams.get("dir");
    const pagination: PaginationState = {
        pageIndex: (Number(page) || 1) - 1,
        pageSize: Number(size) || defaultPageSize
    };
    let sorting: SortingState = [];
    if (sort) {
        sorting.push({
            id: sort,
            desc: dir === 'desc'
        });
    }
    return { pagination, sorting };
}

function getSearchParams(pagination: PaginationState, sorting: SortingState) {
    const record: Record<string, string> = {};
    if (sorting.length) {
        const parts = sorting[0].id.split('.');
        record.sort = parts[parts.length - 1];
        record.dir = sorting[0].desc ? 'desc' : 'asc';
    }
    const { pageIndex, pageSize } = pagination;
    if (pageIndex !== 0)
        record.page = (pageIndex + 1).toString();
    if (pageSize !== defaultPageSize)
        record.size = pageSize.toString();
    return record;
}

export function getApiUrlParamsPart(pagination: PaginationState, sorting: SortingState): string {
    const { pageIndex, pageSize } = pagination;
    let url = ``;
    if (pageIndex)
        url += `page=${pageIndex + 1}`;
    if (pageSize !== defaultPageSize)
        url += (url ? '&' : '') + `size=${pageSize}`;
    if (sorting.length) {
        const parts = sorting[0].id.split('.');
        const field = parts[parts.length - 1];
        url += (url ? '&' : '') + `sort=${field}&desc=${sorting[0].desc}`;
    }
    return url;
}
