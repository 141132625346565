import { Alert } from 'react-bootstrap';
import * as React from "react";

export default function Alerts(props: {
    success?: any,
    error: string | undefined,
    successContent?: any,
    onClose: () => void
}) {
    return <>
        {props.success &&
            <Alert dismissible
                variant="success"
                className="my-3"
                onClose={props.onClose}>
                {props.successContent ?? 'Saved'}
            </Alert>
        }
        {props.error &&
            <Alert dismissible
                variant="danger"
                className="my-3"
                onClose={props.onClose}>
                {props.error}
            </Alert>
        }
    </>
}
