import { ButtonGroup } from 'react-bootstrap';
import { Link, useLocation } from "react-router-dom";
import { UserMode } from '../../types/Common';
import * as React from "react";

export default function UserPanel(props: { userMode: UserMode | undefined }) {
    const { state, pathname } = useLocation();
    const userMode = props.userMode ?? (state ? state as UserMode : undefined);

    if (userMode) {
        const { userId, userName, dictionaryId, dictionaryName, isDirect } = userMode;
        const isDirectUrlPart = isDirect ? '' : '?direct=false';
        return (
            <div>
                <h1 className='mb-3 text-break'>
                    <Link to={`/user/${userId}`}
                        className="fw-bold">
                        {userName}
                    </Link>
                </h1>
                <h2 className="text-break">
                    <span>Dictionary </span>
                    <Link className="fw-bold" to={`/dictionaries/${dictionaryId}`}>{dictionaryName}</Link>, {isDirect ? 'direct' : 'inverse'} direction
                </h2>

                <ButtonGroup className="d-block">
                    <Link className={"btn px-1 px-sm-2 " + (pathname.endsWith('stat') ? "btn-primary" : "btn-outline-primary")}
                        to={`/user/${userId}/dictionary/${dictionaryId}/stat${isDirectUrlPart}`}
                        state={userMode}>
                        Statistics</Link>
                    <Link className={"btn px-1 px-sm-2 " + (pathname.indexOf('/history') >= 0 ? "btn-primary" : "btn-outline-primary")}
                        to={`/user/${userId}/dictionary/${dictionaryId}/history${isDirectUrlPart}`}
                        state={userMode}>
                        History</Link>
                    <Link className={"btn px-1 px-sm-2 " + (pathname.indexOf('/results') >= 0 ? "btn-primary" : "btn-outline-primary")}
                        to={`/user/${userId}/dictionary/${dictionaryId}/results${isDirectUrlPart}`}
                        state={userMode}>
                        Results</Link>
                    <Link className={"btn px-1 px-sm-2 " + (pathname.indexOf('similar') >= 0 ? "btn-primary" : "btn-outline-primary")}
                        to={`/user/${userId}/dictionary/${dictionaryId}/similar-cards${isDirectUrlPart}`}
                        state={userMode}>
                        Similar</Link>
                </ButtonGroup>
            </div>
        )
    }
    else
        return <></>
}
