import { Link } from 'react-router-dom';
import { HistoryStat, ModeStat } from '../../../types/StatTypes';
import DiffSpan from './../../general/DiffSpan';

interface Diff {
    avgStrength: number,
    memorized: number,
    updations: number,
    studied: number
}

export default function TodayYesterdayDiff(props: {
    modeStat: ModeStat | undefined,
    todayStat: HistoryStat,
    yesterdayStat: HistoryStat | undefined,
    previousModeStat: ModeStat | undefined,
    previousTodayStat: HistoryStat | undefined
}) {
    const { modeStat, todayStat, yesterdayStat, previousModeStat, previousTodayStat } = props;

    let todayDiff: Diff | undefined;
    let previousTodayDiff: Diff | undefined;
    let yesterdayDiff: Diff | undefined;

    if (modeStat && todayStat) {
        todayDiff = getDiff(modeStat, todayStat);

        if (previousModeStat && previousTodayStat)
            previousTodayDiff = getDiff(previousModeStat, previousTodayStat)

        if (yesterdayStat)
            yesterdayDiff = getDiff(todayStat, yesterdayStat);
    }

    function getDiff(stat1: HistoryStat | ModeStat, stat2: HistoryStat): Diff {
        return {
            avgStrength: Math.round(1000 * (stat1.avgStrength - stat2.avgStrength)),
            memorized: stat1.memorized - stat2.memorized,
            updations: stat1.updations - stat2.updations,
            studied: stat1.weak + stat1.medium + stat1.strong + stat1.onHold - stat2.weak - stat2.medium - stat2.strong -
                stat2.onHold
        }
    }

    return (
        <>
            {yesterdayDiff &&
                <>
                    <Link to={`/user/${modeStat!.userId}/dictionary/${modeStat!.dictionaryId}/history?isDirect=${modeStat!.isDirect}`} >
                        y:
                        <span>{yesterdayDiff.avgStrength}</span>
                        \
                        <span>{yesterdayDiff.memorized}</span>
                        \
                        <span>{yesterdayDiff.updations}</span>
                        {yesterdayDiff.studied !== 0 &&
                            <span>\{yesterdayDiff.studied}</span>
                        }
                    </Link>
                    <>&thinsp;|&thinsp;</>
                </>
            }
            {todayDiff &&
                <span>
                    t:
                    <DiffSpan value={todayDiff.avgStrength} oldValue={previousTodayDiff?.avgStrength} />
                    \
                    <DiffSpan value={todayDiff.memorized} oldValue={previousTodayDiff?.memorized} />
                    \
                    <DiffSpan value={todayDiff.updations} oldValue={previousTodayDiff?.updations} />
                    {todayDiff.studied !== 0 &&
                        <>\<DiffSpan value={todayDiff.studied} oldValue={previousTodayDiff?.studied} /></>
                    }
                </span>
            }
        </>
    )
}
