import { ColumnDef } from '@tanstack/react-table';
import React, { ChangeEvent } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useFetchOperation } from '../../service/Operation';
import Alerts from '../general/Alerts';
import AutoPaginationTable from '../general/AutoPaginationTable';
import Error from '../general/Error';
import Loader from '../general/Loader';
import ProgressButton from '../general/ProgressButton';
import { CardHandler, Dictionary } from './../../types/Common';
import DicPanel from './DicPanel';

interface HandleDictionaryPage {
    dictionary: Dictionary,
    cardHandlerId?: number
    cardHandlers: CardHandler[]
}

interface HandleDictionaryResponseDto {
    changed: number,
    requests: number,
    processed: number,
    duplicates: number,
    invalid: number,
    total: number,
    requestedCards: HandledCard[]
}

interface HandledCard {
    id: number,
    challenge: string,
    challengeAux: string,
    answer: string,
    example: string,
    isRequested: boolean,
    isChanged: boolean,
    isDuplicate: boolean,
    isInvalid: boolean,
}

export default function HandleCards() {
    const params = useParams();

    const [dictionary, setDictionary] = React.useState<Dictionary>();
    const [cardHandlers, setCardHandlers] = React.useState<CardHandler[]>([]);
    const [cardHandlerId, setCardHandlerId] = React.useState<number>();
    const [emptyChallengeAuxOnly, setEmptyChallengeAuxOnly] = React.useState(true);
    const [handleResponse, setHandleResponse] = React.useState<HandleDictionaryResponseDto>();

    const [getting, startGetting] = useFetchOperation(onGetSuccess, undefined, true);
    const [editing, startEditing, resetEditing] = useFetchOperation(setHandleResponse);

    React.useEffect(() => {
        startGetting('get', `api/dictionaries/${params.id}/handle`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);

    function onGetSuccess(data: HandleDictionaryPage) {
        setDictionary(data.dictionary);
        setCardHandlers(data.cardHandlers);
        setCardHandlerId(data.dictionary.cardHandlerId ?? data.cardHandlers[0].id);
        document.title = `Beautify cards - ${data.dictionary.name} - Dictionaries - ${(document as any).rootTitle}`;
    }

    function onHandlerChange(e: ChangeEvent<HTMLSelectElement>) {
        setCardHandlerId(parseInt(e.target.value));
    }

    function onNoChallengeAuxChange(e: React.ChangeEvent<HTMLInputElement>) {
        setEmptyChallengeAuxOnly(e.target.checked);
    }

    async function onHandleClick() {
        setHandleResponse(undefined);
        const body = {
            dictionaryId: dictionary!.id,
            cardHandlerId,
            emptyChallengeAuxOnly
        };
        startEditing('post', `api/dictionaries/handle`, body);
    }

    const successContent = `${handleResponse?.changed} cards changed (${handleResponse?.requests} requests, ${handleResponse?.duplicates} duplicates, ${handleResponse?.invalid} invalid) from ${handleResponse?.processed} selected from ${handleResponse?.total} total cards`;

    return (
        <>
            <DicPanel dictionary={dictionary} />

            {getting.active ?
                <Loader />
                :
                !cardHandlers?.length ?
                    <Error text={getting.error} />
                    :
                    <div className="w-100">
                        <h2>Beautify cards</h2>

                        <div className="width-limit">
                            <Form>
                                <FormGroup>
                                    <Form.Label htmlFor="handlerId">Handler</Form.Label>
                                    <Form.Select id="handlerId"
                                        value={cardHandlerId}
                                        onChange={onHandlerChange}>
                                        {cardHandlers.map(x =>
                                            <option value={x.id} key={x.id}>{x.name}</option>)}
                                    </Form.Select>
                                </FormGroup>

                                <FormGroup>
                                    <Form.Check
                                        id="additionalId"
                                        type="checkbox"
                                        label="Only with empty Additional task phrase"
                                        className="text-start my-2"
                                        checked={emptyChallengeAuxOnly}
                                        onChange={onNoChallengeAuxChange} />
                                </FormGroup>

                                <div className="btn-panel">
                                    <ProgressButton className="d-block mx-auto btn-wide"
                                        onClick={onHandleClick}
                                        active={editing.active}
                                        text="Beautify"
                                        success={editing.success} />
                                </div>
                            </Form>

                            <Alerts success={editing.success}
                                error={editing.error}
                                onClose={() => resetEditing()}
                                successContent={successContent} />
                        </div>

                        {handleResponse?.requestedCards &&
                            <AutoPaginationTable className="my-3"
                                columns={columns}
                                data={handleResponse.requestedCards} />
                        }
                    </div>
            }
        </>
    )
}

const columns: ColumnDef<HandledCard>[] = [
    {
        header: '#',
        accessorKey: 'id',
        cell: props => <Link to={`/cards/${props.row.original.id}`}> {props.row.index + 1}</Link>
    },
    {
        header: 'Task phrase',
        accessorKey: 'challenge',
    },
    {
        header: 'Additional task phrase',
        accessorKey: 'challengeAux',
    },
    {
        header: 'Answer',
        accessorKey: 'answer',
    },
    {
        header: 'Example',
        accessorKey: 'example',
    },
    {
        header: 'Req.',
        accessorKey: 'isRequested',
        cell: props => <span>{props.row.original.isRequested && "✓"}</span>
    },
    {
        header: 'Ch.',
        accessorKey: 'isChanged',
        cell: props => <span>{props.row.original.isChanged && "✓"}</span>
    },
    {
        header: 'Dupl.',
        accessorKey: 'isDuplicate',
        cell: props => <span>{props.row.original.isDuplicate && "✓"}</span>
    },
    {
        header: 'Inv.',
        accessorKey: 'isInvalid',
        cell: props => <span>{props.row.original.isInvalid && "✓"}</span>
    },
]
