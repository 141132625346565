import * as React from 'react';
import { ModeStat } from '../../../types/StatTypes';
import DiffSpan from '../../general/DiffSpan';
import { Link } from 'react-router-dom';

export function CurrentModeStat(props: { modeStat: ModeStat; previousModeStat: ModeStat | undefined; mixedReadyCount: number | undefined; }) {
    const modeStat = props.modeStat;
    const previousModeStat = props.previousModeStat;
    const mixedReadyCount = props.mixedReadyCount;

    return (
        <Link to={`/user/${modeStat!.userId}/dictionary/${modeStat!.dictionaryId}/stat?isDirect=${modeStat!.isDirect}`} >
            {(modeStat.isInMix && mixedReadyCount !== undefined) &&
                <>
                    ({mixedReadyCount})<> </>
                </>}
            <DiffSpan value={modeStat.ready}
                oldValue={previousModeStat?.ready}
                inverse
                title="Ready to review" />
            <>&thinsp;(</>
            <DiffSpan value={Math.round(modeStat.weakDeltaSum)}
                oldValue={Math.round(previousModeStat?.weakDeltaSum!)}
                inverse
                title="Weak delta sum" />
            <>)&thinsp;|&thinsp;</>
            <DiffSpan value={modeStat.memorized}
                oldValue={previousModeStat?.memorized}
                title="Memorized" />
            <> / </>
            <DiffSpan value={modeStat.studied}
                oldValue={previousModeStat?.studied}
                title="In studying" />
            <> / </>
            <DiffSpan value={modeStat.notSkipped}
                oldValue={previousModeStat?.notSkipped}
                title="Not skipped" />
            <> / </>
            <DiffSpan value={modeStat.total}
                oldValue={previousModeStat?.total}
                title="Total in the dictionary" />
        </Link>
    );
}
