import * as React from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useFetchOperation } from '../../service/Operation';
import { LanguageData } from '../../types/Common';
import Alerts from '../general/Alerts';
import ProgressButton from '../general/ProgressButton';
import LangEditor from './LangEditor';

export default function LangAdd() {
    const [language, setLanguage] = React.useState<LanguageData>({} as LanguageData);

    const [adding, startAdding, resetAdding] = useFetchOperation();

    function langEditorOnChange(val: LanguageData) {
        setLanguage({ ...language, ...val });
    }

    function onAddClick() {
        startAdding('post', `api/languages`, language);
    }

    React.useEffect(() => {
        document.title = `Add language - ${(document as any).rootTitle}`;
    }, []);

    return (
        <div className="width-limit">
            <h1>Add Language</h1>

            <Form className="form-large">
                <LangEditor language={language} onChange={langEditorOnChange} />

                <div className="btn-panel">
                    <ProgressButton className="btn-wide"
                        onClick={onAddClick}
                        active={adding.active}
                        success={adding.success}
                        text="Save" />
                </div>
            </Form>

            <Alerts success={adding.success}
                error={adding.error}
                onClose={() => resetAdding()}
                successContent={
                    <>
                        <>Language added. </>
                        <Link to='/languages' >Return to the list</Link>
                    </>} />

        </div>)
}
