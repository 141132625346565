import * as React from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { LanguageData } from './../../types/Common';

export default function LangEditor(props: {
    language: LanguageData,
    onChange(l: LanguageData): void
}) {

    const onPropertyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.id as keyof LanguageData;
        const value = e.target.value;
        props.onChange({ ...props.language, [name]: value });
    }

    return (
        <>
            <FormGroup>
                <Form.Label htmlFor="name">Native name</Form.Label>
                <Form.Control
                    id="name"
                    value={props.language.name || ''}
                    onChange={onPropertyChange} />
            </FormGroup>

            <FormGroup>
                <Form.Label htmlFor="code">Code</Form.Label>
                <Form.Control
                    id="code"
                    value={props.language.code || ''}
                    onChange={onPropertyChange}
                    maxLength={2} />
            </FormGroup>

            <FormGroup>
                <Form.Label htmlFor="wiktionaryName">Wiktionary English name</Form.Label>
                <Form.Control
                    id="wiktionaryName"
                    value={props.language.wiktionaryName || ''}
                    onChange={onPropertyChange} />
            </FormGroup>

            <FormGroup>
                <Form.Label htmlFor="reversoName">Reverso url name</Form.Label>
                <Form.Control
                    id="reversoName"
                    value={props.language.reversoName || ''}
                    onChange={onPropertyChange} />
            </FormGroup>
        </>
    )
}
