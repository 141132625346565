import { ChangeEvent } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DictionarySlim } from '../../types/Common';

export default function ModeSelector(props: {
    dictionaryId: number,
    isDirect: boolean,
    onDictionaryChange: (id: number, name: string) => void,
    onIsDirectChange: (value: boolean) => void,
    dictionaries: DictionarySlim[] | undefined
}) {

    function onDictionaryChange(e: ChangeEvent<HTMLSelectElement>) {
        const dictionaryName = e.target.options[e.target.selectedIndex].text;
        props.onDictionaryChange(Number(e.target.value), dictionaryName);
    }

    function onIsDirectChange(e: ChangeEvent<HTMLInputElement>) {
        props.onIsDirectChange(e.target.value === 'true');
    }

    return (
        <>
            <InputGroup className="my-3">
                <label htmlFor="dictionaryId" className="me-2 align-self-center">Dictionary:</label>

                <Form.Select
                    id="dictionaryId"
                    name="dictionaryId"
                    value={props.dictionaryId}
                    onChange={onDictionaryChange}>
                    {props.dictionaries?.map(d =>
                        <option value={d.id} key={d.id}>{d.name}</option>)}
                </Form.Select>

                <Link className="btn btn-outline-primary px-1"
                    to={`/dictionaries/${props.dictionaryId}`}>
                    Open
                </Link>
            </InputGroup>

            <div className="my-2">
                <span>Direction:</span>
                <Form.Check type="radio"
                    className="d-inline-block mx-2"
                    id="isDirect1"
                    name="isDirect"
                    label="direct"
                    value="true"
                    checked={props.isDirect}
                    onChange={onIsDirectChange} />
                <Form.Check type="radio"
                    className="d-inline-block"
                    id="isDirect2"
                    name="isDirect"
                    label="inverse"
                    value="false"
                    checked={!props.isDirect}
                    onChange={onIsDirectChange} />
            </div>
        </>
    )
}
