// Entity
export interface Entity {
    id: number
}

// Language
export interface Language extends LanguageData, Entity { }

export interface LanguageData {
    name: string,
    code: string,
    wiktionaryName: string,
    reversoName: string
}

// Dictionary
export interface Dictionary extends DictionaryData, Entity {
    authorId: number,
    authorName: string,
    challengeLanguage?: Language,
    answerLanguage?: Language,
    reversoToLanguage?: Language,
    total?: number
}

export interface DictionaryData {
    name: string,
    challengeLanguageId?: number,
    answerLanguageId?: number,
    reversoToLanguageId?: number,
    choiceTrainingAvailable: boolean,
    onlyChallengeAuxAvailable: boolean,
    cardHandlerId?: number
}

export interface DictionaryBulkPage {
    dictionary: Dictionary
    content: string
}

export interface DictionaryAddingCards {
    id: number,
    name: string,
    cardHandlerId: number | undefined
}

export interface DictionarySlim {
    id: number,
    name: string
}

// Card
export interface Card extends CardData, Entity { }

export interface CardData {
    challenge: string,
    challengeAux: string,
    answer: string,
    example: string,
}

export interface ExtendedCard extends Card {
    dictionaryId: number,
    dictionaryName: string
}

export interface AddCardsResponse {
    addedCardsCount: number,
    totalCards: number,
    duplicates: Duplicate[],
    invalidLines: string[],
    isLimitExceeded: boolean
}
export interface CardHandler {
    id: number,
    name: string
}

export interface Duplicate extends Card {
    duplicateChallengeAux: string,
    duplicateAnswer: string,
    duplicateExample: string,
}

export interface CardSlim {
    id: number,
    challenge: string,
    answer: string
}

export interface CardPage {
    card: EditCard,
    dictionaryId: number,
    dictionaryName: string,
    isEditable: boolean,
    cardHandlerId: number | undefined,
    dictionaryPaths: DictionaryPaths,
    cardHandlers: CardHandler[]
}

export interface EditCard extends Card {
    marked: boolean,
    skipped: boolean,
}

export interface HandledCard extends EditCard {
    isChanged: boolean,
    isRequested: boolean,
    isDuplicate: boolean,
    isInvalid: boolean
}

export interface SimilarCards extends ExtendedCard {
    similar: ExtendedCard[] | undefined,
    //computed
    userMode: UserMode
}

// Result
export interface Result {
    id: number,
    firstUpdated: string | undefined,
    interpretUpdated: string | undefined,
    choiceUpdated: string | undefined,
    updatedCount: number,
    strength: number
}

export interface ExtendedResult extends Result {
    interpretUpdatedInterval: string | undefined,
    interpretReviewInterval: string | undefined,
    interpretReviewRate: number | undefined,

    choiceUpdatedInterval: string | undefined,
    choiceReviewInterval: string | undefined,
    choiceReviewRate: number | undefined,

    ready: string,
    readyInterval: string | undefined,
    reviewRate: number,

    isStrengthValid: boolean,

    // computed:
    isInterpretReady: boolean,
    isInterpretDue: boolean,
    isInterpretLast: boolean,
    isChoiceReady: boolean,
    isChoiceDue: boolean,
    isChoiceLast: boolean,
}

export interface CardResult extends Result {
    id: number,
    dictionaryId: number,
    dictionaryName: string,
    isDirect: boolean,
    card: Card
}

export interface ResultPage {
    result: ExtendedResult,
    card: Card,
    userMode: UserMode
}

export interface CardResultStatus {
    card: Card,
    result: ExtendedResult | undefined,
    status: CardStatus,
    userMode?: UserMode,
}

export enum CardStatus {
    ToBeStudied,
    Preview,
    ActiveStudying,
    Skipped,
}

// User
export interface User {
    id: number,
    userName: string,
    email: string,
    emailConfirmed: boolean,
    currentDictionaryId: number,
    currentDictonaryName: string,
    currentIsDirect: boolean,
    mixedModeActive: boolean,
    mixedModesLimit: number,
    randomReviewOrder: boolean,
    proportionalMixed: boolean,
    language: string,
    lastActivity: string,
    role: string,
    currentModes: NamedMode[] | undefined
}

// Mode
export interface Mode {
    dictionaryId: number,
    isDirect: boolean
}

export interface NamedMode {
    dictionaryId: number,
    isDirect: boolean,
    dictionaryName: string
}

export interface UserMode {
    userId: number,
    userName: string,
    dictionaryId: number,
    dictionaryName: string,
    isDirect: boolean
}

// Request params
export interface RequestParams {
    page?: number,
    size?: number,
    sort?: string,
    desc?: boolean
}

export interface DictionaryRequestParams extends RequestParams {
    id: number
}

// Other
export interface DictionaryPaths {
    challengeLanguageCode: string | undefined,
    answerLanguageCode: string | undefined,
    reversoPath: string | undefined,
    wiktionaryLanguage: string | undefined
}
export interface CountData {
    count: number
}
