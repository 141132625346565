import * as React from 'react';
import { Alert, Form } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { useFetchOperation } from '../../service/Operation';
import { useAppDispatch, useAppSelector } from '../../store';
import { addingActionCreators } from '../../store/Adding';
import { AddCardsResponse, DictionaryData, Language } from '../../types/Common';
import Error from '../general/Error';
import Loader from '../general/Loader';
import ProgressButton from '../general/ProgressButton';
import TabTextarea from '../general/TabTextarea';
import DicEditor from './DicEditor';

interface AddDictionaryResponse extends AddCardsResponse {
    dictionaryId: number
}

export default function DicAdd() {
    const cardsContent = useAppSelector((state) => state.adding.dictionaryContent);
    const dispatch = useAppDispatch();
    const [languages, setLanguages] = React.useState<Language[]>();

    const [dictionary, setDictionary] = React.useState<DictionaryData>({
        name: '',
        challengeLanguageId: undefined,
        answerLanguageId: undefined,
        choiceTrainingAvailable: true,
        onlyChallengeAuxAvailable: true,
    });
    const [dictionaryId, setDictionaryId] = React.useState<number>();
    const [isLimitExceeded, setIsLimitExceeded] = React.useState<boolean>();

    const [getting, startGetting] = useFetchOperation(setLanguages, undefined, true);
    const [adding, startAdding, resetAdding] = useFetchOperation(onAddSuccess);

    React.useEffect(() => {
        startGetting('get', `api/languages`);
        document.title = `Add dictionary - ${(document as any).rootTitle}`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onAddSuccess(result: AddDictionaryResponse) {
        setDictionaryId(result.dictionaryId);
        setIsLimitExceeded(result.isLimitExceeded);
        if (result?.addedCardsCount)
            dispatch(addingActionCreators.setDictionaryContent(''));
    }

    function dicEditorOnChange(val: DictionaryData) {
        setDictionary({ ...dictionary, ...val });
    }

    function cardsContentChanged(newText: string) {
        dispatch(addingActionCreators.setDictionaryContent(newText));
    }

    function onAddClick() {
        const body = { ...dictionary, cardsContent };
        startAdding('post', `api/dictionaries`, body);
    }

    return (
        <>
            {getting.active ?
                <Loader />
                :
                !languages ?
                    <Error text={getting.error} />
                    :
                    adding.success ?
                        <Navigate to={`/dictionaries/${dictionaryId}`} state={{ isLimitExceeded }} />
                        :
                        <div className="width-limit">
                            <h1>Add dictionary</h1>
                            <Form>
                                <DicEditor dictionary={dictionary}
                                    languages={languages}
                                    cardHandlers={undefined}
                                    onChange={dicEditorOnChange} />

                                <TabTextarea text={cardsContent}
                                    onChange={cardsContentChanged}
                                    placeholder="Lines in the format:
Task phrase + tab + meaning phrase + [tab + additional task phrase (transcription/conjugation)] + [tab + example]

*optional fields are in brackets

*equals character '=' is accepted instead of tab"/>

                                <ProgressButton className="my-4 d-block mx-auto btn-wide"
                                    onClick={onAddClick}
                                    success={adding.success}
                                    active={adding.active}
                                    text="Add"/>
                            </Form>

                            {adding.error &&
                                <Alert dismissible
                                    variant="danger"
                                    className="my-3"
                                    onClose={resetAdding}>
                                    {adding.error}
                                </Alert>
                            }
                        </div>
            }
        </>
    )
}
