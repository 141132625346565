import { ColumnDef } from '@tanstack/react-table';
import * as React from 'react';
import { Link } from "react-router-dom";
import { Duplicate } from '../../types/Common';
import AutoPaginationTable from '../general/AutoPaginationTable';

export default function CardAddWarning(props: {
    duplicates: Duplicate[] | undefined,
    invalidLines: string[] | undefined,
    openInNew: boolean
}) {
    const columns = React.useMemo<ColumnDef<Duplicate>[]>(() => [
        {
            header: 'Task phrase',
            accessorKey: 'challenge',
            cell: p => <Link to={`/cards/${p.row.original.id}`} target={props.openInNew ? '_blank' : undefined}> {p.row.original.challenge}</Link>
        },
        {
            header: 'Answer',
            accessorKey: 'duplicateAnswer',
        },
        {
            header: 'Existing answer',
            accessorKey: 'answer',
        },
    ], [props.openInNew]);

    return (
        <>
            {!!props.duplicates?.length &&
                <>
                    <h5>Duplicates:</h5>

                    <AutoPaginationTable className="my-3"
                        columns={columns}
                        data={props.duplicates} />
                </>
            }
            {!!props.invalidLines?.length &&
                <>
                    <h5>Invalid lines:</h5>
                    {props.invalidLines.map(line => <p key={line}>{line}</p>)}
                </>
            }
        </>
    )
}
