import * as React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../store';
import { trainingActionCreators } from '../../store/Training';
import { ModeSettingSlim, ServicePanelVisibility, TrainingCard } from '../../types/TrainingTypes';
import ModeSettingEditor from '../trainingSettings/ModeSettingEditor';
import ProgressButton from '../general/ProgressButton';
import { useFetchOperation } from '../../service/Operation';
import { TaskKinds } from '../trainingSettings/Enums';

const servicePanelVisibilityTextes: [ServicePanelVisibility, string][] = [
    [ServicePanelVisibility.Always, 'Always'],
    [ServicePanelVisibility.InterpretAndOnMistake, 'Study / On mistake'],
    [ServicePanelVisibility.InterpretAndCombined, 'Study / Combined'],
    [ServicePanelVisibility.Interpret, 'Study'],
    [ServicePanelVisibility.None, 'Disabled'],
];

export default function TrainingOptions(props: { card: TrainingCard }) {
    const dispatch = useAppDispatch();
    const auth = useAppSelector(state => state.auth);
    const taskKind = props.card.modeInfo.modeSetting.taskKind;
    const servicePanelVisibility = useAppSelector(state => state.training).servicePanelVisibility;
    const [isSettingsVisible, setIsSettingsVisible] = React.useState(false);
    const [editing, startEditing, resetEditing] = useFetchOperation(onEditingSuccess);

    function onServicePanelVisibilityChange(visibility: ServicePanelVisibility) {
        dispatch(trainingActionCreators.setServicePanelVisibility(visibility));
    }

    function showModeSettings() {
        setIsSettingsVisible(!isSettingsVisible);
    }

    function setSettings(modeSetting: ModeSettingSlim): void {
        dispatch(trainingActionCreators.setModeSetting(modeSetting));
    }

    function saveSetting() {
        const setting = {
            ...props.card.modeInfo.modeSetting,
            dictionaryId: props.card.modeInfo.dictionaryId,
            isDirect: props.card.modeInfo.isDirect,
        }
        startEditing('post', `api/study/settings/single`, setting);
    }

    function onEditingSuccess() {
        setIsSettingsVisible(false);
        setTimeout(resetEditing, 2000);
    }

    return (
        <>
            {auth.isUser && isSettingsVisible && props.card.modeInfo?.modeSetting &&
                <div className="border border-primary rounded-3 bg-light w-auto mx-auto pt-1 pb-2 px-3 mt-3 d-block">
                    <div>
                        <p>
                            <span className="fs-5 fw-bold">{props.card.modeInfo.dictionaryName}</span>
                            {!props.card.modeInfo.isDirect &&
                                <> (inverse direction)</>
                            }
                            <> settings</>
                        </p>
                        <ModeSettingEditor setting={props.card.modeInfo.modeSetting}
                            setSetting={setSettings}
                            isGetting={false}
                        />
                    </div>

                    <ProgressButton className="btn-wide my-2"
                        onClick={saveSetting}
                        active={editing.active}
                        disabled={editing.active}
                        success={editing.success} />
                </div>
            }

            <div className="btn-panel my-2">
                <Button variant="outline-light"
                    className="btn-wide"
                    onClick={showModeSettings}>
                    {TaskKinds.find(x => x[0] === taskKind)?.[1]}
                </Button>

                <Dropdown className="d-inline-block">

                    <Dropdown.Toggle variant="outline-light"
                        className="btn-sm" >
                        Service panel: {servicePanelVisibilityTextes.find(x => x[0] === servicePanelVisibility)?.[1]}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {servicePanelVisibilityTextes.map(([key, value]) =>
                            <Dropdown.Item as={Button}
                                key={key}
                                variant="outline"
                                active={key === servicePanelVisibility}
                                onClick={() => onServicePanelVisibilityChange(key)}>
                                {value}
                            </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    );
}
