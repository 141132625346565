import { ColumnDef } from '@tanstack/react-table';
import * as React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import apiClient from '../../service/ApiClient';
import { useFetchOperation } from '../../service/Operation';
import { trainingActionCreators } from '../../store/Training';
import { ExtendedCard, SimilarCards } from '../../types/Common';
import AutoPaginationTable from '../general/AutoPaginationTable';
import Error from '../general/Error';
import Loader from '../general/Loader';
import SearchPanel from './SearchPanel';
import UserPanel from '../user/UserPanel';

interface FoundCard extends ExtendedCard {
    added: boolean
}

export interface SimilarCardsPage extends SimilarCards {
    userId: number,
    userName: string,
    isDirect: boolean
}

export default function CardSimilar() {
    const params = useParams();
    const dispatch = useDispatch();

    const [pageData, setPageData] = React.useState<SimilarCardsPage>();
    const [text, setText] = React.useState('');
    const [full, setFull] = React.useState(false);
    const [foundCards, setFoundCards] = React.useState<FoundCard[]>();
    const [search, startSearch] = useFetchOperation(onSearchSuccess);

    const [getting, startGetting] = useFetchOperation(onGettingSuccess, undefined, true);

    const foundColumns = React.useMemo<ColumnDef<FoundCard>[]>(() => [
        {
            header: 'Challenge',
            accessorKey: 'challenge',
            cell: props => <text>
                <Link to={`/cards/${props.row.original.id}/similar`}>{props.row.original.challenge}</Link>{props.row.original.dictionaryName
                    ? <span> ({props.row.original.dictionaryName})</span>
                    : <></>}
            </text>
        },
        {
            header: 'Answer',
            accessorKey: 'answer',
        },
        {
            header: 'Add',
            cell: props => props.row.original.added
                ? <></>
                : <Button className="btn-sm pt-0" onClick={() => { onAddClick(props.row.original.id) }}>+</Button>
        },
    ], [pageData, foundCards]);

    React.useEffect(() => {
        startGetting('get', `api/cards/${params.id}/similar`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);

    function onGettingSuccess(relation: SimilarCardsPage) {
        relation.similar?.forEach(c => {
            if (c.dictionaryId === relation.dictionaryId)
                c.dictionaryName = '';
        });
        setPageData(relation);
        document.title = `${relation.challenge} - Search similar cards - ${(document as any).rootTitle}`;
    }

    function onSearch(text: string, full: boolean) {
        setFoundCards(undefined);
        setText(text);
        setFull(full);
        startSearch('get', `api/cards/search/${pageData!.dictionaryId}?text=${text}&extended=${full}`);
    }

    function onSearchSuccess(cards: ExtendedCard[]) {
        cards = cards.filter(x => x.id !== pageData!.id);
        cards.forEach(c => {
            if (c.dictionaryId === pageData?.dictionaryId)
                c.dictionaryName = '';
        });
        setFoundCardsWithAdded(cards, pageData!.similar);
    }

    function onDeleteClick(similarCardId: number) {
        apiClient.post(`api/cards/${pageData!.id}/delete-similar/${similarCardId}`).then(() => {
            const similar = pageData!.similar?.filter(x => x.id !== similarCardId);
            setPageData({ ...pageData!, similar });
            setFoundCardsWithAdded(foundCards, similar);
            dispatch(trainingActionCreators.refreshCard({ ...pageData!, similar }) as any);
        });
    }

    function onAddClick(similarCardId: number) {
        if (!pageData!.similar?.find(x => x.id === similarCardId)) {
            apiClient.post(`api/cards/${pageData!.id}/add-similar/${similarCardId}`).then(() => {
                const cardToAdd = foundCards!.find(x => x.id === similarCardId);
                const similar = [...(pageData!.similar ?? []), cardToAdd!];
                setPageData({ ...pageData!, similar });
                setFoundCardsWithAdded(foundCards, similar);
                dispatch(trainingActionCreators.refreshCard({ ...pageData!, similar }) as any);
            });
        }
    }

    function setFoundCardsWithAdded(foundCards: ExtendedCard[] | undefined, similar: ExtendedCard[] | undefined) {
        setFoundCards(foundCards?.map(x => ({ ...x, added: !!similar?.find(y => y.id === x.id) })));
    }

    return (
        <>
            <UserPanel userMode={pageData} />

            {getting.active ?
                <Loader />
                :
                !pageData ?
                    <Error text={getting.error} />
                    :
                    <div className="width-limit">
                        <h3 className="mt-3">Search similar cards</h3>

                        <div className="btn-panel">
                            <Link to={`/cards/${pageData.id}`}
                                className="btn btn-outline-primary btn-sm">
                                Edit card
                            </Link>

                            <Link to={`/cards/${pageData.id}/results`}
                                className="btn btn-outline-primary btn-sm">
                                Results
                            </Link>
                        </div>

                        <Form className="form-large">
                            <div className="form-control disabled my-3 lh-sm py-1 text-break">
                                {pageData.challenge} {pageData.challengeAux && <span className="text-secondary fs-3">{pageData.challengeAux}</span>}
                            </div>
                            <div className="form-control disabled my-3 lh-sm py-1 text-break">{pageData.answer}</div>
                        </Form>

                        {!!pageData.similar?.length &&
                            <div className="my-4 table-responsive w-100">
                                <table className="table table-striped table-bordered table-sm text-center">
                                    <tbody>
                                        {pageData.similar.map(x =>
                                            <tr key={x.id}>
                                                <td>
                                                    <Link to={`/cards/${x.id}/similar`}>{x.challenge}</Link>
                                                    {x.dictionaryName
                                                        ? <span> ({x.dictionaryName})</span>
                                                        : <></>}
                                                </td>
                                                <td>{x.answer}</td>
                                                <td>
                                                    <button className="btn-close align-middle"
                                                        onClick={() => onDeleteClick(x.id)} />
                                                </td>
                                            </tr>
                                        )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }

                        <SearchPanel
                            text={text}
                            full={full}
                            onSearch={onSearch}
                            autoFocus={true} />

                        {search.active ?
                            <Loader />
                            :
                            foundCards ?
                                foundCards?.length ?
                                    <AutoPaginationTable className="my-3"
                                        columns={foundColumns}
                                        data={foundCards} />
                                    :
                                    <p className="my-3">No cards found</p>
                                :
                                <></>
                        }
                    </div>
            }
        </>
    )
}
