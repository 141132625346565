//import * as moment from 'moment';
import * as React from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Link, useParams } from 'react-router-dom';
import { useFetchOperation } from '../../service/Operation';
import { useAppSelector } from '../../store';
import { Card, ExtendedResult, ResultPage, UserMode } from '../../types/Common';
import AddToStudying from '../card/edit/AddToStudying';
import { apiDateTimeFormat } from '../formatters/DateTimeFormat';
import { getTimeSpan } from '../formatters/TimeSpanFormat';
import Alerts from '../general/Alerts';
import Error from '../general/Error';
import Loader from '../general/Loader';
import useOnChange from '../general/OnChange';
import ProgressButton from '../general/ProgressButton';
import UserPanel from '../user/UserPanel';
import moment from 'moment';

interface LocalExtendedResult extends ExtendedResult {
    firstUpdatedLocal: string | undefined,
    interpretUpdatedLocal: string | undefined,
    choiceUpdatedLocal: string | undefined,
    readyLocal: string | undefined,
}

export default function ResultEdit() {
    const auth = useAppSelector(state => state.auth);
    const params = useParams();

    const [result, setResult] = React.useState<LocalExtendedResult>();
    const [card, setCard] = React.useState<Card>();
    const [userMode, setUserMode] = React.useState<UserMode>();

    const [deleted, setDeleted] = React.useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const { onNumberChange } = useOnChange(result, setResult);

    const [getting, startGetting] = useFetchOperation(onGetSuccess, undefined, true);
    const [editing, startEditing, resetEditing] = useFetchOperation(onEditingSuccess);
    const [deleting, startDeleting, resetDeleting] = useFetchOperation(() => setDeleted(true));

    React.useEffect(() => {
        startGetting('get', `api/results/${params.id}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onGetSuccess(page: ResultPage) {
        setCard(page.card);
        setUserMode(page.userMode)

        const result = page.result as LocalExtendedResult;
        LocalizeResult(result);
        setResult(result);

        document.title = `Result - ${page.card.challenge} ${(page.userMode.isDirect ? '' : '(inverse) ')}- ${page.userMode.userName} - ${(document as any).rootTitle}`;
    }

    const onDatePropertyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const localName = event.target.id + "Local" as keyof LocalExtendedResult;
        const localValue = event.target.value;

        const utcName = event.target.id as keyof LocalExtendedResult;
        const utcValue = localValue ? moment(localValue)?.utc()?.format() : undefined;
        setResult({ ...result!, [localName]: localValue, [utcName]: utcValue });
    }

    function LocalizeResult(result: LocalExtendedResult) {
        result.firstUpdatedLocal = result.firstUpdated ? moment.utc(result.firstUpdated).local().format(apiDateTimeFormat) : undefined;
        result.interpretUpdatedLocal = result.interpretUpdated ? moment.utc(result.interpretUpdated).local().format(apiDateTimeFormat) : undefined;
        result.choiceUpdatedLocal = result.choiceUpdated ? moment.utc(result.choiceUpdated).local().format(apiDateTimeFormat) : undefined;
        result.readyLocal = result.ready ? moment.utc(result.ready).local().format(apiDateTimeFormat) : undefined;
    }

    function saveClick() {
        startEditing('put', `api/results`, result);
        resetDeleting();
    }

    function onEditingSuccess(updatedResult: LocalExtendedResult) {
        LocalizeResult(updatedResult);
        setResult(updatedResult);
        setTimeout(resetEditing, 2000);
    }

    function onDeleteClick() {
        startDeleting('delete', `api/results/${params.id}`);
        resetEditing();
        setModalShow(false);
    }

    function increaseStrength() {
        const strength = Math.min(result!.strength! + 0.1, 1);
        setResult({ ...result!, strength });
    }

    function decreaseStrength() {
        const strength = Math.max(result!.strength! - 0.1, 0);
        setResult({ ...result!, strength });
    }

    function onAddedToStudying(addedResult: ExtendedResult) {
        const localResult = addedResult as LocalExtendedResult;
        LocalizeResult(localResult)
        setResult(localResult);
    }

    return (
        <>
            <UserPanel userMode={userMode} />

            {getting.active ?
                <Loader />
                :
                !result || !card || !userMode ?
                    <Error text={getting.error} />
                    :
                    <div className="width-limit">
                        <div className="btn-panel">
                            <Link to={`/cards/${card.id}`}
                                className="btn btn-outline-primary btn-sm">
                                Edit card
                            </Link>

                            <Link to={`/cards/${card.id}/similar`}
                                className="btn btn-outline-primary btn-sm">
                                Card similar
                            </Link>

                            {!result.firstUpdated &&
                                <AddToStudying
                                    userId={userMode.userId}
                                    cardId={card.id}
                                    isDirect={userMode.isDirect}
                                    disabled={deleted}
                                    onAdded={onAddedToStudying} />
                            }
                        </div>

                        <h2 className="mb-0">{auth.isModerator ? 'Edit' : 'View'} result</h2>

                        <Form className="form-large width-limit mb-3">
                            <Form.Group>
                                <Form.Label>Task phrase</Form.Label>
                                <div className="form-control disabled text-start">{card.challenge}</div>
                            </Form.Group>

                            {card.challengeAux &&
                                <Form.Group>
                                    <Form.Label>Additional task phrase</Form.Label>
                                    <div className="form-control disabled text-start">{card.challengeAux ?? ' '}</div>
                                </Form.Group>
                            }

                            <Form.Group>
                                <Form.Label>Answer</Form.Label>
                                <div className="form-control disabled text-start">{card.answer}</div>
                            </Form.Group>

                            {card.example &&
                                <Form.Group>
                                    <Form.Label>Example</Form.Label>
                                    <div className="form-control disabled text-start">{card.example ?? ' '}</div>
                                </Form.Group>
                            }
                            <div className="row gx-3">
                                <Form.Group controlId="firstUpdated"
                                    className="col-12 col-sm-6">
                                    <Form.Label>Start of learning</Form.Label>
                                    <Form.Control autoComplete="off"
                                        readOnly={!auth.isModerator}
                                        value={result.firstUpdatedLocal ?? ''}
                                        onChange={onDatePropertyChange} />
                                </Form.Group>

                                <Form.Group controlId="updatedCount"
                                    className="col-12 col-sm-6">
                                    <Form.Label>Number of updates</Form.Label>
                                    <Form.Control autoComplete="off"
                                        readOnly={!auth.isModerator}
                                        value={result.updatedCount ?? ''}
                                        onChange={onNumberChange} />
                                </Form.Group>

                                <Form.Group controlId="interpretUpdated"
                                    className="col-12 col-sm-6">
                                    <Form.Label>Updated (study)</Form.Label>
                                    <Form.Control autoComplete="off"
                                        readOnly={!auth.isModerator}
                                        value={result.interpretUpdatedLocal ?? ''}
                                        onChange={onDatePropertyChange} />
                                </Form.Group>

                                <Form.Group controlId="choiceUpdated"
                                    className="col-12 col-sm-6">
                                    <Form.Label>Updated (choice)</Form.Label>
                                    <Form.Control autoComplete="off"
                                        readOnly={!auth.isModerator}
                                        value={result.choiceUpdatedLocal ?? ''}
                                        onChange={onDatePropertyChange} />
                                </Form.Group>

                                <Form.Group controlId="strength"
                                    className="col-12">
                                    <Form.Label>Strength</Form.Label>

                                    <InputGroup className="mb-3">
                                        <Form.Control autoComplete="off"
                                            readOnly={!auth.isModerator}
                                            value={result.strength ?? ''}
                                            onChange={onNumberChange} />
                                        <Button variant="outline-danger"
                                            style={{ minWidth: '45px' }}
                                            onClick={decreaseStrength}>
                                            <span className="fs-3">&minus;</span>
                                        </Button>
                                        <Button variant="outline-success"
                                            style={{ minWidth: '44px' }}
                                            onClick={increaseStrength}>
                                            <span className="fs-3">+</span>
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                            </div>

                            {auth.isModerator &&
                                <div className="btn-panel my-1">
                                    <ProgressButton className="btn-wide"
                                        onClick={saveClick}
                                        disabled={deleting.active || deleted}
                                        active={editing.active}
                                        success={editing.success} />

                                    <ProgressButton variant="outline-danger"
                                        className="btn-wide"
                                        onClick={() => setModalShow(true)}
                                        disabled={editing.active || deleted}
                                        active={deleting.active}
                                        success={deleting.success}
                                        text="Delete"
                                        successText="Deleted" />
                                </div>
                            }

                            <Modal show={modalShow} onHide={() => setModalShow(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Confirmation</Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <p className="fw-bold">Are you sure you want to delete this result?</p>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setModalShow(false)}>Cancel</Button>
                                    <Button variant="danger"
                                        onClick={onDeleteClick}>
                                        Delete
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            <Alerts error={editing.error}
                                onClose={() => resetEditing()} />

                            <Alerts success={deleting.success}
                                successContent="Result successfully deleted"
                                error={deleting.error}
                                onClose={() => resetDeleting()} />

                            <div className="row gx-3 mb-3">
                                <Form.Group
                                    className="col-12 col-sm-6">
                                    <Form.Label>Ready date</Form.Label>
                                    <Form.Control readOnly
                                        value={result.readyLocal ?? ''} />
                                </Form.Group>

                                <Form.Group
                                    className="col-12 col-sm-6">
                                    <Form.Label>Ready date interval</Form.Label>
                                    <Form.Control readOnly
                                        value={getTimeSpan(result.readyInterval) ?? ''} />
                                </Form.Group>
                            </div>

                            <div className="row gx-3">
                                <Form.Group
                                    className="col-12 col-sm-6 order-sm-6">
                                    <Form.Label>Study review rate</Form.Label>
                                    <Form.Control readOnly
                                        value={result.interpretReviewRate ?? ''} />
                                </Form.Group>

                                <Form.Group
                                    className="col-12 col-sm-6 order-sm-2">
                                    <Form.Label>Study updated interval</Form.Label>
                                    <Form.Control readOnly
                                        value={getTimeSpan(result.interpretUpdatedInterval) ?? ''} />
                                </Form.Group>

                                <Form.Group
                                    className="col-12 col-sm-6 order-sm-4 mb-3 mb-sm-1">
                                    <Form.Label>Study standard review interval</Form.Label>
                                    <Form.Control readOnly
                                        value={getTimeSpan(result.interpretReviewInterval) ?? ''} />
                                </Form.Group>

                                <Form.Group
                                    className="col-12 col-sm-6 order-sm-1">
                                    <Form.Label>Choice review rate</Form.Label>
                                    <Form.Control readOnly
                                        value={result.choiceReviewRate ?? ''} />
                                </Form.Group>

                                <Form.Group
                                    className="col-12 col-sm-6 order-sm-3">
                                    <Form.Label>Choice updated interval</Form.Label>
                                    <Form.Control readOnly
                                        value={getTimeSpan(result.choiceUpdatedInterval) ?? ''} />
                                </Form.Group>

                                <Form.Group
                                    className="col-12 col-sm-6 order-sm-5">
                                    <Form.Label>Choice standard review interval</Form.Label>
                                    <Form.Control readOnly
                                        value={getTimeSpan(result.choiceReviewInterval) ?? ''} />
                                </Form.Group>
                            </div>
                        </Form>
                    </div>
            }
        </>
    )
}
