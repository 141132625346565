import * as React from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useFetchOperation } from '../../../service/Operation';
import { useAppDispatch, useAppSelector } from '../../../store';
import { trainingActionCreators } from '../../../store/Training';
import { Card } from '../../../types/Common';
import Alerts from '../../general/Alerts';
import ProgressButton from '../../general/ProgressButton';

export default function ActionsPanel(props: {
    card: Card,
    setDeleted: (x: boolean) => void,
    updateCard: (x: Card) => void,
    toggleHandlingActive: () => void,
    deleted: boolean
}) {
    const auth = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();

    const [editing, startEditing, resetEditing] = useFetchOperation(onEditingSuccess);
    const [deleting, startDeleting, resetDeleting] = useFetchOperation(() => props.setDeleted(true));

    const [modalShow, setModalShow] = React.useState(false);

    function onSaveClick() {
        startEditing('put', `api/cards`, props.card);
        resetDeleting();
    }

    function onEditingSuccess(responseCard: Card) {
        dispatch(trainingActionCreators.refreshCard(responseCard));
        props.updateCard(responseCard);
        setTimeout(resetEditing, 2000);
    }

    function onDeleteClick() {
        startDeleting('delete', `api/cards/${props.card.id}`);
        resetEditing();
        setModalShow(false);
    }

    function onToggleHandlingClick() {
        props.toggleHandlingActive();
    }

    return (
        <div className="btn-panel">
            <ProgressButton className="btn-wide"
                onClick={onSaveClick}
                disabled={deleting.active || props.deleted}
                active={editing.active}
                success={editing.success} />

            <ProgressButton variant="outline-danger"
                className="btn-wide"
                onClick={() => setModalShow(true)}
                active={deleting.active}
                disabled={editing.active || props.deleted}
                success={deleting.success}
                text="Delete"
                successText="Deleted" />

            {auth.isCreator &&
                <Button variant="outline-primary"
                    className="btn-wide"
                    onClick={onToggleHandlingClick}
                    disabled={editing.active || deleting.active || props.deleted}>
                    Beautify
                </Button>
            }

            <Modal show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p className="fw-bold">Are you sure you want to delete this card and all its results?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalShow(false)}>Cancel</Button>
                    <Button variant="danger"
                        onClick={onDeleteClick}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>


            <Alerts error={editing.error}
                onClose={() => resetEditing()} />

            <Alerts success={deleting.success}
                error={deleting.error}
                successContent="Card was successfully deleted"
                onClose={() => resetDeleting()} />
        </div>
    )
}
