import { TaskKind } from "../../types/TrainingTypes";

export const Quantities:[number, string][] = [
    [0, 'disabled'],
    [4, 'extra low'],
    [7, 'low'],
    [10, 'medium'],
    [13, 'high'],
    [16, 'extra high'],
    [20, 'insane']
];

export const TaskKinds: [TaskKind, string][] = [
    [TaskKind.BothAlways, 'Task+Aux always (St, Co, Ch)'],
    [TaskKind.BothOnCombinedAndInterpret, 'Task+Aux on Study & Combined'],
    [TaskKind.BothOnInterpret, 'Task+Aux on Study'],
    [TaskKind.BothOnRevealed, 'Task+Aux on Study revealed'],
    [TaskKind.TaskOnly, 'Task only'],
    [TaskKind.AuxOnly, 'Aux only'],
];
